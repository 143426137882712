import { createI18n } from 'vue-i18n';

const i18n = createI18n({
  legacy: false, // Важно указать это свойство, чтобы использовать Composition API
  locale: 'ua',
  messages: {
   
    ua: require('./locales/uk.json'), // Загрузка JSON файла для украинского языка
    ru: require('./locales/ru.json'),
    // Другие языковые версии
  }
});

export default i18n;