<template>
    <div class="preloader content-text-style">
        <svg class="truck" viewBox="0 0 48 24" width="48px" height="24px">
            <g fill="none" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" transform="translate(0,2)">
                <g class="truck__body">
                    <g stroke-dasharray="105 105">
                        <polyline class="truck__outside1" points="2 17,1 17,1 11,5 9,7 1,39 1,39 6" />
                        <polyline class="truck__outside2" points="39 12,39 17,31.5 17" />
                        <polyline class="truck__outside3" points="22.5 17,11 17" />
                        <polyline class="truck__window1" points="6.5 4,8 4,8 9,5 9" />
                        <polygon class="truck__window2" points="10 4,10 9,14 9,14 4" />
                    </g>
                    <polyline class="truck__line" points="43 8,31 8" stroke-dasharray="10 2 10 2 10 2 10 2 10 2 10 26" />
                    <polyline class="truck__line" points="47 10,31 10" stroke-dasharray="14 2 14 2 14 2 14 2 14 18" />
                </g>
                <g stroke-dasharray="15.71 15.71">
                    <g class="truck__wheel">
                        <circle class="truck__wheel-spin" r="2.5" cx="6.5" cy="17" />
                    </g>
                    <g class="truck__wheel">
                        <circle class="truck__wheel-spin" r="2.5" cx="27" cy="17" />
                    </g>
                </g>
            </g>
        </svg>
        <p>Завантаження...</p>
    </div>
  </template>

<script>

export default {
    name: "PreloaderShow"
}
</script>

<style lang="scss" scoped>
    .preloader {
        text-align: center;
        padding: 20px;
        height: 50vh;
        display: flex;
        align-content: center;
        justify-content: center;
        flex-flow: column;
    }

    $debug: false;
    $ease-in: cubic-bezier(0.33,0,0.67,0);
    $ease-out: cubic-bezier(0.33,1,0.67,1);

.truck {
	@if $debug == true {
		outline: 1px solid;
	}
	--dur: 3s;
	display: block;
	width: 12em;
	height: auto;
    margin: 0 auto 20px;

	&__body,
	&__line,
	&__outside1,
	&__outside2,
	&__outside3,
	&__wheel,
	&__wheel-spin,
	&__window1,
	&__window2 {
		animation: truck-body var(--dur) linear infinite;
        stroke: #F58A1F;
	}
	&__body {
		transform-origin: 17px 11px;
	}
	&__line {
		animation-name: truck-line;
	}
	&__outside1 {
		animation-name: truck-outside1;
	}
	&__outside2 {
		animation-name: truck-outside2;
	}
	&__outside3 {
		animation-name: truck-outside3;
	}
	&__wheel {
		animation-name: truck-wheel;

		&-spin {
			animation-name: truck-wheel-spin;
			transform-origin: 6.5px 17px;
		}
		&:nth-child(2) {
			animation-delay: calc(var(--dur) * 0.0625);
		}
		&:nth-child(2) &-spin {
			transform-origin: 27px 17px;
		}
	}
	&__window1 {
		animation-name: truck-window1;
	}
	&__window2 {
		animation-name: truck-window2;
	}
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
	:root {
		--bg: hsl(var(--hue),90%,10%);
		--fg: hsl(var(--hue),90%,50%);
	}
}

/* Animations */
@keyframes truck-body {
	from,
	12.5%,
	25%,
	37.5%,
	50%,
	62.5%,
	75%,
	87.5%,
	to {
		animation-timing-function: $ease-in;
		transform: translate(0,0) rotate(0);
	}
	6.25%,
	18.75%,
	31.25%,
	43.75%,
	56.25%,
	68.75%,
	81.25%,
	93.75% {
		animation-timing-function: $ease-out;
		transform: translate(0,1px) rotate(-0.75deg);
	}
}
@keyframes truck-line {
	from {
		stroke-dashoffset: -18;
	}
	to {
		stroke-dashoffset: 78;
	}
}
@keyframes truck-outside1 {
	from {
		stroke-dashoffset: 105;
	}
	to {
		stroke-dashoffset: -105;
	}
}
@keyframes truck-outside2 {
	from {
		stroke-dashoffset: 168;
	}
	to {
		stroke-dashoffset: -42;
	}
}
@keyframes truck-outside3 {
	from {
		stroke-dashoffset: 192;
	}
	to {
		stroke-dashoffset: -18;
	}
}
@keyframes truck-wheel {
	from,
	12.5%,
	25%,
	37.5%,
	50%,
	62.5%,
	75%,
	87.5%,
	to {
		animation-timing-function: $ease-in;
		transform: translate(0,0);
	}
	6.25%,
	18.75%,
	31.25%,
	43.75%,
	56.25%,
	68.75%,
	81.25%,
	93.75% {
		animation-timing-function: $ease-out;
		transform: translate(0,-1px);
	}
}
@keyframes truck-wheel-spin {
	from {
		stroke-dashoffset: -15.71;
		transform: rotate(0);
	}
	to {
		stroke-dashoffset: 15.71;
		transform: rotate(-4turn);
	}
}
@keyframes truck-window1 {
	from {
		stroke-dashoffset: -21;
	}
	to {
		stroke-dashoffset: 189;
	}
}
@keyframes truck-window2 {
	from {
		stroke-dashoffset: -39;
	}
	to {
		stroke-dashoffset: 171;
	}
}
</style>