<template>
  <section class="container-footer">
    <div class="container">
      <div class="footer">
        <div class="footer__block">
          <div class="contacts">
            <div class="logo">
              <img :src="require('../assets/Logo (1).svg')" />
            </div>
            <div class="info">
              <span>
                {{ $t("ourText") }}
              </span>
            </div>
            <div class="contact">
              <button @click="fbLink">
                <img :src="require('../assets/Fb.svg')" />
              </button>
              <button @click="youtubeLink">
                <img :src="require('../assets/Yt.svg')" />
              </button>
              <button @click="telegramLink">
                <img :src="require('../assets/Pb.svg')" />
              </button>
              <button @click="tiktokLink">
                <img :src="require('../assets/Sb.svg')" />
              </button>
              <button><img :src="require('../assets/Wp.svg')" /></button>
            </div>
          </div>
          <div class="informations">
            <div class="navigation">
              <div class="title"><span>{{ $t("widthProject") }}</span></div>
              <div class="links">
                <router-link :to="{ path: '/services' }" class="link"
                  ><span>{{ $t("ourServices") }}</span></router-link
                >
                <router-link :to="{ path: '/pages/contacts' }" class="link"
                  ><span>{{ $t("ourContacts") }}</span></router-link
                >
                <router-link :to="{ path: '/pages/carier' }" class="link"
                  ><span>{{ $t("ourCarier") }}</span></router-link
                >
              </div>
            </div>
            <div class="navigation">
              <div class="title">
                <span>{{ $t("assist") }}</span>
              </div>
              <div class="links">
                <router-link :to="{ path: '/reviews' }" class="link"
                  ><span>{{ $t("reviews") }}</span></router-link
                >
                <router-link :to="{ path: '/pages/logistic' }" class="link"
                  ><span>{{ $t("logistics") }}</span></router-link
                >
                <router-link :to="{ path: '/pages/relocation' }" class="link"
                  ><span>{{ $t("relocation") }}</span></router-link
                >
              </div>
            </div>
            <div class="navigation">
              <!-- <div class="title">
                <span>{{ $t("pageTitle") }}</span>
              </div> -->
              <div class="links">
                <router-link :to="{ path: '/' }" class="link"
                  ><span>{{ $t("contacts") }}</span></router-link
                >
                <router-link :to="{ path: '/' }" class="link"
                  ><span>{{ $t("orderAcall") }}</span></router-link
                >
                <router-link :to="{ path: '/' }" class="link"
                  ><span>{{ $t("orderAnEstimate") }}</span></router-link
                >
              </div>
            </div>
          </div>
        </div>

        <div class="footer__footer">
          <span>© 2000-2023, All Rights Reserved | Made with love ❤️ <a class="develop" href="https://devels.litesoft.agency/" terget="_blank" rel="no-follow">litesoft.agency</a></span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useI18n } from "vue-i18n";
export default {
  name: "FooterLoyaut",
  setup() {
    const { t } = useI18n();

    const fbLink = () => {
      window.open("https://www.facebook.com/serezhik.dnepr/", "_blank");
    };
    const youtubeLink = () => {
      window.open("https://www.youtube.com/@Ser-ezhik_com_ua/", "_blank");
    };
    const telegramLink = () => {
      window.open("https://t.me/BotSer_Ezhik_bot", "_blank");
    };
    const tiktokLink = () => {
      window.open("https://www.tiktok.com/@ser_ezhik_", "_blank");
    };
    return {
      t,
      fbLink,
      youtubeLink,
      telegramLink,
      tiktokLink,
    };
  },
};
</script>

<style lang="scss" scoped>
.container-footer {
  // max-width: 1300px;
  margin: 0 auto;
  // padding-top: 52px;
  // padding-bottom: 32px;
  border-top: 1px solid #f2f4f5;
}
.footer {
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding-top: 52px;
  padding-bottom: 32px;

  .develop {
    color: #9a9ea6;
    text-decoration: none;

    &:hover {
      color: #23262f;
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: center;

    span {
      color: #9a9ea6;
      font-family: "Manrope", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;
    }
  }

  &__block {
    display: flex;
    flex-direction: row;
    gap: 150px;
    .contacts {
      display: flex;
      flex-direction: column;
      gap: 30px;
      max-width: 380px;
      .logo {
      }
      .info {
        span {
          color: #9a9ea6;
          font-family: "Manrope", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
      .contact {
        display: flex;
        gap: 10px;
        button {
          background: white;
          border: none;
          cursor: pointer;
        }
      }
    }
    .informations {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      .navigation {
        display: flex;
        flex-direction: column;
        gap: 24px;
        .title {
          span {
            color: #000;
            font-family: "Manrope", sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
          }
        }
        .links {
          display: flex;
          flex-direction: column;
          gap: 20px;
          .link {
            white-space: nowrap;
            text-decoration: none;
            span {
              color: #9a9ea6;
              font-family: "Manrope", sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1190px) {
}
@media (max-width: 1100px) {
  .footer .footer__block {
    align-items: center;
    flex-direction: column;
  }
  .footer .footer__block .informations {
    justify-content: space-evenly;
  }
}
@media (max-width: 600px) {
  .footer {
    .footer__block {
      flex-direction: column;
      gap: 30px;
    }
  }
}

@media (max-width: 400px) {
  .footer__block .informations .navigation .title span {
    font-size: 16px;
  }
  .footer__block .informations .navigation .links .link span {
    font-size: 12px;
  }
  .footer__block .contacts .contact {
    justify-content: center;
  }
  .footer .footer__block .informations {
    justify-content: space-between;
  }
}
</style>
