<template>
  <section class="modal-window" v-if="showModal === true">
    <div class="container-modal-window">
      <div class="modal-close">
        <button @click="$emit('closeModal')">
          <img :src="require('../assets/close.svg')" />
        </button>
      </div>
      <div class="modal-block">
        <div class="modal-contacts">
          <div class="title">
            <h2>{{ $t('formTitle') }}</h2>
            <p>{{ $t('formSubTitle') }}</p>
          </div>

          <div class="inputs">
            <input
              type="text"
              :placeholder="$t('formName')"
              class="name-email"
              v-model="userName"
              :class="{ 'send-info': validUserName === true }"
            />
            <input
              type="email"
              :placeholder="$t('formEmail')"
              class="name-email"
              v-model="userEmail"
            />
            <!-- <vue-tel-input  v-model="phone"  :default-country="'ua'" inputOptions.placeholder="ADASADD"></vue-tel-input> -->
            <VueTelInput
              v-model="phone"
              v-bind="bindProps"
              mode="international"
              :default-country="'ua'"
              :class="{ 'send-info': validUserPhone === true }"
              :validCharactersOnly="true"
              v-mask="'+38(0##) ###-##-##'"
            ></VueTelInput>
            <textarea
              :placeholder="$t('formComment')"
              class="comment"
              style="width: 339px; height: 84px; resize: none"
              v-model="usersComents"
            ></textarea>
          </div>
          <div class="send-button">
            <button
              :disabled="!fieldsAreFilled"
              @click="sendUserInfo"
              :class="{ 'disabled-button': !fieldsAreFilled }"
            >
              {{ $t('formSubmit') }}
            </button>
          </div>
          <div class="info-message">
            {{ $t('formDesc') }} <span>{{ $t('formMin') }} </span>
          </div>
        </div>
        <div class="modal-info" @click="checkModalValid">
          <div class="city-up">
            <div class="city-name">
              <img :src="require('../assets/Line.svg')" />
              <span>{{ sendInfo.town }}</span>
            </div>
            <div class="city-line"></div>
            <div class="address">
              <span>{{ sendInfo.street }}</span>
            </div>
            <div class="city-line"></div>
            <div class="address">
              <span>{{ sendInfo.streetDown }}</span>
            </div>
            <div class="city-line"></div>
          </div>

          <div class="car-info">
            <div class="car">
              <img :src="require('../assets/cars.svg')" />
              <span>{{ sendInfo.car }}</span>
            </div>
            <div class="car-time">
              <img :src="require('../assets/clock.svg')" />
              <span>{{ sendInfo.carTime }} {{ carTimeLabel }} </span>
            </div>
          </div>
          <div class="car-line"></div>

          <div class="calendars">
            <div class="calendar">
              <img :src="require('../assets/calendar.svg')" /><span>{{
                sendInfo.data
              }}</span>
            </div>
            <div class="line"></div>
            <div class="clock">
              <img :src="require('../assets/clock.svg')" /><span>{{
                sendInfo.time
              }}</span>
            </div>
          </div>
          <div class="calendar-line"></div>

          <div class="loaders">
            <div class="loader-time">
              <img :src="require('../assets/calendar.svg')" /><span
                >{{ sendInfo.workTime }} {{ workTimeLabel }}</span
              >
            </div>
            <div class="line"></div>
            <div class="loader-current">
              <img :src="require('../assets/PP.svg')" /><span
                >{{ sendInfo.loader }} {{ loaderLabel }}</span
              >
            </div>
          </div>
          <div class="loaders-line"></div>

          <div class="modal-price">
            <div class="price">
              <span class="info">{{ $t('formCount') }}</span>
              <span class="sum">~</span>
              <span class="sum">{{ formatNumber(sendInfo.price) }} грн</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, computed, toRefs, watch } from "vue";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import {mask} from 'vue-the-mask'
import axios from "axios";
import numeral from 'numeral';

export default {
  name: "ModalWindow",
  components: {
    VueTelInput,
  },
  directives: {mask},
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    sendInfo: {
      type: Object,
      default: () => {},
    },
    boolTime: {
      type: Boolean,
      default: false,
    },
    showSucces: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const bindProps = ref({
      mode: "international",
      inputOptions: {
        showDialCode: false,
        autofocus: false,
        id: "test",
        placeholder: "Номер телефона *",
      },
    });
    const checkModalValid = () => {
      console.log('yuyy')
      if (userName.value === "" || phone.value === "") {
        toast.error("Напишіть ваше ім`я та телефон", {
          autoClose: 1000,
        });
        return
      }
     
    };
    const fieldsAreFilled = ref(false);
    const phone = ref(null);
    const userName = ref("");
    const userEmail = ref("");
    const validUserName = ref(false);
    const validUserPhone = ref(false);
    const usersComents = ref("")

    const sendUserInfo = () => {
      if (userName.value === "") {
        validUserName.value = true;
        return;
      }
      if (userName.value !== "") {
        validUserName.value = false;
        
      }

      if (phone.value === "") {
        validUserPhone.value = true;
        return;
      }
      if (phone.value !== "") {
        validUserPhone.value = false;
      }
      const botToken = "6685662973:AAHI-9ndigaQqW7ur9578KR_VOolSYr5L0Q";
      const chatId = "-4094200753";     
       const message = `👋🏻 Заявка з калькулятора

🙋‍♂️Ім’я : ${userName.value}
☎️Телефон : ${phone.value}
📧 Email: ${userEmail.value}
📠 Коментар: 
▶️Тип перевезення: По Мiсту
📍Міста: ${sendInfo.value.town}

1️⃣Адрес загрузки: ${sendInfo.value.street}

2️⃣Адреса вигрузки: ${sendInfo.value.streetDown}

👥Кількість вантажників: ${sendInfo.value.loader} вантажника ${sendInfo.value.workTime} годин роботи

🚚Тип автомобіля: ${sendInfo.value.car}
🚚Годин для авто: ${sendInfo.value.carTime}

📆Дата: ${sendInfo.value.data}
⏳Час: ${sendInfo.value.time}
💰Вартість послуг: ${formatNumber(sendInfo.value.price)} грн.`;

      // Формируем URL для отправки сообщения
      const apiUrl = `https://api.telegram.org/bot${botToken}/sendMessage`;

      // Отправляем POST-запрос к API телеграмма
      axios
        .post(apiUrl, {
          chat_id: chatId,
          text: message,
        })
        .then((response) => {
          console.log("Сообщение успешно отправлено:", response.data);
          emit("succes");
        })
        .catch((error) => {
          console.error("Ошибка при отправке сообщения:", error);
          toast.error(error, {
            autoClose: 1000,
          });    
        });
    };
    const { sendInfo } = toRefs(props);

    const carTimeLabel = computed(() => {
      const carTime = sendInfo.value.carTime;

      if (carTime === 1) {
        return "година";
      } else if (carTime >= 2 && carTime <= 4) {
        return "години";
      } else if (carTime >= 5 && carTime <= 10) {
        return "годин";
      } else {
        return "годин";
      }
    });

    const workTimeLabel = computed(() => {
      const workTime = sendInfo.value.workTime;

      if (workTime === 1) {
        return "година";
      } else if (workTime >= 2 && workTime <= 4) {
        return "години";
      } else if (workTime >= 5 && workTime <= 10) {
        return "годин";
      } else {
        return "годин";
      }
    });

    const loaderLabel = computed(() => {
      const loader = sendInfo.value.loader;

      if (loader === 1) {
        return "вантажник";
      } else if (loader >= 2 && loader <= 4) {
        return "вантажники";
      } else if (loader >= 5 && loader <= 10) {
        return "вантажників";
      } else {
        return "вантажник";
      }
    });
    const formatNumber = (number) => {
      return numeral(number).format('0,0').replace(',', ' ');
    };
    watch([userName, phone], () => {
      fieldsAreFilled.value =
        userName.value.trim() !== "" && phone.value.trim() !== "";
    });

    return {
      phone,
      bindProps,
      userName,
      userEmail,
      validUserName,
      validUserPhone,
      sendUserInfo,
      carTimeLabel,
      workTimeLabel,
      loaderLabel,
      checkModalValid,
      fieldsAreFilled,
      usersComents,
      formatNumber
    };
  },
};
</script>

<style lang="scss" scoped>
.vti__dropdown-list{
  width: 353px !important;
}
.disabled-button {
  background-color: gray !important;
  cursor: not-allowed !important;
  color: white;
}
.send-info {
  border: solid 1px red !important;
}
.vue-tel-input {
  height: 48px;
  border-radius: 9px;
  border: 1px solid #e3e5e5;
}
.vue-tel-input input {
  border-radius: 0 9px 9px 0 !important;
}
.modal-window {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(56, 69, 93, 0.5);
  z-index: 11;
  overflow-y: auto;
}
.modal-close {
  position: absolute;
  right: -15px;
  top: -20px;
  button {
    cursor: pointer;
    border-radius: 32px;
    background: var(--neutrals-8, #fcfcfd);
    box-shadow: 0px 8px 16px -8px rgba(15, 15, 15, 0.1);
    border: none;
    img {
      padding: 8px 10px 8px 10px;
    }
  }
}
.container-modal-window {
  position: absolute;
  left: 50%;
  top: 50%;
  min-width: 870px;
  min-height: 676px;
  border-radius: 16px;
  background: #fcfcfd;
  transform: translate(-50%, -50%);
  box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.08);

  .modal-block {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding-top: 79px;

    .modal-contacts {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      .title {
        display: flex;
        flex-direction: column;
        gap: 16px;
        h2 {
          color: #23262f;
          text-align: center;
          font-family: "Manrope", sans-serif;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 36px;
        }
        p {
          color: #72777a;
          text-align: center;
          font-family: "Manrope", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
      .inputs {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .name-email {
          max-width: 339px;
          height: 48px;
          padding-left: 16px;
          border-radius: 8px;
          border: 1px solid #e3e5e5;
          background: #fff;
          color: var(--ink-lighter, #72777a);
          font-family: "Manrope", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }
        .comment {
          border-radius: 8px;
          border: 1px solid #e3e5e5;
          background: #fff;
          padding-left: 16px;
          padding-top: 16px;
          color: var(--ink-lighter, #72777a);
          font-family: "Manrope", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }
      }
      .send-button {
        button {
          display: flex;
          border: none;
          justify-content: center;
          align-items: center;
          border-radius: 48px;
          background: #f58a1f;
          color: #fff;
          text-align: center;
          font-family: "Manrope", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
          width: 327px;
          height: 48px;
        }
      }
      .info-message {
        color: var(--Dark, #23262f);
        text-align: center;
        font-family: "Manrope", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        span {
          color: #f58a1f;
        }
      }
    }
    .modal-info {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding-top: 110px;
      padding-left: 25px;
      .city-up {
        display: flex;
        gap: 20px;
        flex-direction: column;
        .city-line {
          display: flex;
          align-items: center;
          gap: 20px;
          flex-direction: row; /* Горизонтальное расположение */
          position: relative;
        }

        .city-line::after {
          content: "";
          width: 100%;
          background-color: #f2f4f5;
          height: 1px;
          position: absolute;
          top: 50%; /* Положение по середине вертикально */
          left: 0;
          transform: translateY(-50%);
        }
        .city-name {
          display: flex;
          align-items: center;
          gap: 12px;
          span {
            color: var(--neutrals-2, #23262f);
            font-family: "Manrope", sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px;
          }
        }

        .address {
          span {
            color: var(--sky-dark, #979c9e);
            font-family: "Manrope", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px; /* 128.571% */
            text-decoration-line: underline;
          }
        }
      }

      .city-down {
        display: flex;
        gap: 20px;
        flex-direction: column;
        .city-line {
          display: flex;
          align-items: center;
          gap: 20px;
          flex-direction: row; /* Горизонтальное расположение */
          position: relative;
        }

        .city-line::before {
          content: "";
          width: 100%;
          background-color: #f2f4f5;
          height: 1px;
          position: absolute;
          top: 50%; /* Положение по середине вертикально */
          left: 0;
          transform: translateY(-50%);
        }
        .city-name {
          display: flex;
          align-items: center;
          gap: 12px;
          span {
            color: var(--neutrals-2, #23262f);
            font-family: "Manrope", sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px;
          }
        }

        .address {
          span {
            color: var(--sky-dark, #979c9e);
            font-family: "Manrope", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px; /* 128.571% */
            text-decoration-line: underline;
          }
        }
      }

      .car-info {
        padding-top: 5px;
        padding-bottom: 5px;
        display: flex;
        gap: 10px;
        flex-direction: column;
        .car {
          display: flex;
          align-items: center;
          gap: 12px;
          span {
            color: var(--neutrals-2, #23262f);
            font-family: "Manrope", sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px;
          }
        }

        .car-time {
          display: flex;
          align-items: center;
          gap: 12px;
          span {
            color: var(--neutrals-2, #23262f);
            font-family: "Manrope", sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px;
          }
        }
      }
      .car-line {
        content: "";
        width: 100%;
        background-color: #f2f4f5;
        height: 1px;
      }

      .calendars {
        display: flex;
        gap: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
        .calendar {
          display: flex;
          align-items: center;
          gap: 12px;
          span {
            color: var(--neutrals-2, #23262f);
            font-family: "Manrope", sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px;
          }
        }
        .clock {
          display: flex;
          align-items: center;
          gap: 12px;
          span {
            color: var(--neutrals-2, #23262f);
            font-family: "Manrope", sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px;
          }
        }
        .line {
          border-left: 1px solid #f2f4f5;
          height: 100%;
          margin: 0 10px;
        }
      }
      .calendar-line {
        content: "";
        width: 100%;
        background-color: #f2f4f5;
        height: 1px;
      }

      .loaders {
        display: flex;
        gap: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
        .loader-time {
          display: flex;
          align-items: center;
          gap: 12px;
          span {
            color: var(--neutrals-2, #23262f);
            font-family: "Manrope", sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px;
          }
        }
        .loader-current {
          display: flex;
          align-items: center;
          gap: 12px;
          span {
            color: var(--neutrals-2, #23262f);
            font-family: "Manrope", sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px;
          }
        }
        .line {
          border-left: 1px solid #f2f4f5;
          height: 100%;
          margin: 0 10px;
        }
      }
      .loaders-line {
        content: "";
        width: 100%;
        background-color: #f2f4f5;
        height: 1px;
      }
    }

    .modal-price {
      padding-top: 20px;
      .price {
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: center;

        .info {
          color: var(--sky-dark, #979c9e);
          font-family: "Manrope", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
        .sum {
          color: var(--Dark, #23262f);
          text-align: center;
          font-family: "Manrope", sans-serif;
          font-size: 24px;
          font-style: normal;
          font-weight: 800;
          line-height: 32px;
        }
      }
    }
  }
}
@media (max-width: 920px) {
  .container-modal-window {
    min-width: 685px;
  }
}
@media (max-width: 720px) {
  .container-modal-window {
    width: 100%;
    height: 100%;
    overflow: auto;
    border-radius: 0px;
  }
  .container-modal-window .modal-block {
    flex-direction: column-reverse;
    align-items: center;
    gap: 50px;
    padding-top: 40px;
  }
  .modal-close {
    right: 0px;
    top: 0px;
  }
  .container-modal-window .modal-block .modal-info {
    padding-top: 0px;
    padding-left: 0px;
  }
  .container-modal-window .modal-block .modal-contacts {
    padding-bottom: 30px;
  }
}
</style>
