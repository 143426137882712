import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Service from '../views/ServiceView.vue'
import Services from '../views/ServicesView.vue'
import Cars from '../views/CarsView.vue'
import Car from '../views/CarView.vue'
import Reviews from '../views/ReviewsView.vue'
import Pages from '../views/Page.vue'


const routes = [
  {
    path: '/:slug',
    name: 'homeSlug',
    component: HomeView
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/cars',
    name: 'cars',
    component: Cars
  },
  {
    path: '/car/:slug',
    name: 'car',
    component: Car
  },
  {
    path: '/services',
    name: 'services',
    component: Services
  },
  {
    path: '/service/:slug',
    name: 'service',
    component: Service
  },
  {
    path: '/reviews',
    name: 'reviews',
    component: Reviews
  },
  {
    path: '/pages/:slug',
    name: 'Pages',
    component: Pages,
    props: true
  }
 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
