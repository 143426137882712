<template>
    <div class="page-single-wrapper" v-if="car && car.status === 'publish'">
        <div class="page-single">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                    <router-link to="/">Головна</router-link>
                    </li>
                    <li class="breadcrumb-item">
                    <router-link to="/cars">Наш автопарк</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">{{ car.title }}</li>
                </ol>
            </nav>
            <h1>{{ car.title }}</h1>
            <div class="content-text-style" v-html="car.content"></div>
        </div>
    </div>  
    <div v-else-if="loading">
        <!-- Display a preloader while the data is being fetched -->
        <Preloader />
    </div>
    <div v-else>
        <!-- Display a message or a fallback content if the page is not published -->
        <Empty />
    </div>
</template>

<script>
import axios from 'axios'
import { carsQuery } from "@/graphql/cars.query";
import Empty from '@/components/Empty.vue'
import Preloader from '@/components/Preloader.vue'

export default {
    name: "CarView",
    components: {
        Empty,
        Preloader
    },
    data() {
        return {
            car: {},
            loading: true
        };
    },
    mounted() {
        const slug = this.$route.params.slug;
        axios.post("https://admin.ser-ezhik.com.ua/graphql", {
            query: carsQuery,
            variables: { slug }
        })
            .then(response => {
                this.car = response.data.data.allCar.nodes.find(car => car.slug === slug);
                this.loading = false;
            })
            .catch(error => {
                console.error(error);
                this.loading = false;
            });
    }
}
</script>