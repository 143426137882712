import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from '../i18n'

// import VueTelInput from 'vue3-tel-input'
// import 'vue3-tel-input/dist/vue3-tel-input.css'
// const VueTelInputOptions = {
//     mode: "international",
//     onlyCountries: ['UA']
//   }

 
createApp(App).use(store).use(router).use(i18n).mount('#app');
