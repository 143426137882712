<template>
    <div class="mobile-menu" :class="{ 'is-open': isOpen }">
      <nav class="menu-nav">
        <ul>
          <li><a href="#">Home</a></li>
          <li><a href="#">About</a></li>
          <li><a href="#">Contact</a></li>
          <li><a href="#">Services</a></li>
        </ul>
      </nav>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue'
  
  export default {
    setup() {
      const isOpen = ref(true)
  
      return {
        isOpen
      }
    }
  }
  </script>
  
  <style lang="scss">
  .mobile-menu {
    position: relative;
    &.is-open {
     .menu-nav {
        transform: translateX(0);
      }
    }
   .menu-nav {
        position: fixed;
        top: 71px;
        border-top: 1px solid #ccc;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: #fff;
      padding: 20px;
      transform: translateX(-100%);
      transition: transform 0.3s ease-in-out;
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          margin-bottom: 20px;
          a {
            color: #000;
            text-decoration: none;
          }
        }
      }
    }
  }
  </style>