<template>
  <section class="section-padding about-us-section section-container-old">
    <div v-if="loading">
      <Preloader />
    </div>
    <div v-else class="container block-info">
      <div class="about-us">
        <h2 class="title">{{ title }}</h2>
        <div class="content-text-style" v-html="text"></div>
      </div>
      <div class="img-info">
        <img :src="image" v-if="altText" :alt="altText" />
        <img :src="image" v-else alt="Опис зображення недоступний" />
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import { aboutQuery } from '../graphql/aboutus.query'
import { useI18n } from "vue-i18n";
import Preloader from '@/components/Preloader.vue'
export default {
  name: "BlockInfo",
  components: {
      Preloader
    },
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  data() {
    return {
      title: '',
      text: '',
      image: '',
      altText: '',
      loading: true
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const response = await axios.post('https://admin.ser-ezhik.com.ua/graphql', {
          query: aboutQuery,
        });

        const data = response.data.data.optionsUK.settingsForPage.proNas;
        this.loading = false
        this.title = data.title;
        this.text = data.text;
        this.image = data.image.node.sourceUrl;
        this.altText = data.image.node.altText;
        
      } catch (error) {
        console.error(error);
        this.loading = false
      }
    },
  },
};
</script>

<style lang="scss">
.block-info {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  .about-us {
    display: flex;
    flex-direction: column;
    gap: 35px;
    .title {
      color: #23262f;
      font-family: "Manrope", sans-serif;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 38px;
    }
    .text {
      display: flex;
      flex-direction: column;
      gap: 30px;
      span {
        color: #72777a;
        font-family: "Manrope", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}
@media (max-width: 1400px) {
  .block-info .img-info img {
    max-width: 448px;
  }
}
@media (max-width: 1190px) {
  .block-info .img-info img {
    max-width: 448px;
  }
}
@media (max-width: 950px) {
  .block-info {
    flex-direction: column;
  }
}

@media (max-width: 770px) {
  .block-info {
    .img-info img {
      max-width: 384px;
    }
  }
}
</style>
