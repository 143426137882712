<template>
  <div>
    <modal-window
      @closeModal="closeModalWindow"
      :showModal="modalWindow"
      :sendInfo="sendInfo"
      :boolTime="boolTime"
      :showSucces="succesBlock"
      @succes="succesBlockView"
    ></modal-window>
    <ModalPlace
      @closeModal="closeModalWindowPlace"
      :showModal="modalPlace"
      :sendInfo="sendInfo"
      :boolTime="boolTime"
      :showSucces="succesBlock"
      @succes="succesBlockView"
    ></ModalPlace>
    <div class="container">
      <section class="main-section home__info">
        <div class="info-img">
          <div class="partner">
            <span class="partner-text">
              {{ $t("partner") }}
              <img :src="require('../assets/g526.svg')"/>
            </span>
            <img class="map" :src="require('../assets/Group 13216.svg')" />
            <div class="partner-adaptiv">
              <span>{{ $t("partnerAdaptiv") }}</span>
            </div>
          </div>
          <div class="application">
            <div class="navigation-buttons">
              <button
                class="relocation"
                :class="{ choice: changeBtn === 'Переїзд по Україні' }"
                @click="clickChangeBtn('Переїзд по Україні')"
              >
                <span class="relocation-text">{{ $t("transportation") }}</span>
              </button>
              <button
                class="logistics"
                :class="{ choice: changeBtn === 'Переїзд по місту' }"
                @click="clickChangeBtnMv('Переїзд по місту')"
              >
                <span class="logistics-text">{{ $t("relocation") }}</span>
              </button>
            </div>

            <div
              class="wrapper-inputs"
              v-if="changeBtn === 'Переїзд по Україні'"
            >
              <div class="geolocation-inputs">
                <city-select
                  @selectOptions="selectedOptionsUp"
                  :citySelect="selectOptions"
                  :icon-src="require('../assets/Line.svg')"
                  :class="{ 'valid-input': boolSelectValUp === true }"
                  :placeholder="$t('citySelect')"
                ></city-select>
                <!-- <hr /> -->
                <div class="hr"></div>
                <custom-input
                  v-model="inputValue"
                  :class="{ 'valid-input': boolInput === true }"
                  :placeholder="$t('nameLoadingTruck')"
                ></custom-input>
                <div class="hr"></div>
                <div class="check-box" @click="toggleCheckBox">
                  <input type="checkbox" :checked="isChecked" /><span
                    >{{ $t('loadingTruck') }}</span
                  >
                </div>

                <div class="worker" v-if="isChecked">
                  <div class="worker-loader">
                    <custom-select
                      @selectOptions="selectedLoader"
                      :clearable="clearable"
                      :placeholder="$t('qtyLoader')"
                      :icon-src="require('../assets/PP.svg')"
                      :options="loader"
                      :class="{ 'valid-input': boolLoader === true }"
                    ></custom-select>
                    <span
                      class="countLoad"
                      v-if="
                        currentLoader !== '' &&
                        typeof currentLoader === 'number' &&
                        currentLoader !== 0
                      "
                      >Вант.</span
                    >
                  </div>
                  <div class="worker-loaders-time">
                    <custom-select
                      class="after-timeWorker"
                      @selectOptions="selectLoadTime"
                      :placeholder="$t('qtyHourLoader')"
                      :options="workTimes"
                      :clearable="clearableUp"
                      :fontSize="'fontSize'"
                      :icon-src="require('../assets/clock.svg')"
                    ></custom-select>
                    <span
                      class="countLoad"
                      v-if="loadTime && loadTime <= 10 && loadTime !== 1"
                      >Год.</span
                    >
                  </div>
                </div>
                <div class="hr"></div>
                <!-- <button @click="clearForm">test</button> -->
              </div>
              <div class="geolocation-inputs">
                <city-select
                  @selectOptions="selectedOptionsDown"
                  :citySelect="selectOptionsTwo"
                  :icon-src="require('../assets/Line1.svg')"
                  :class="{ 'valid-input': boolSelectValDown === true }"
                  :placeholder="$t('citySelect')"
                ></city-select>
                <!-- <hr /> -->
                <div class="hr"></div>

                <custom-input
                  v-model="inputValueDown"
                  :class="{ 'valid-input': downInput === true }"
                  :placeholder="$t('nameUnloadingTruck')"
                ></custom-input>
                <div class="hr"></div>
                <div class="check-box" @click="toggleCheckBoxDown">
                  <input type="checkbox" :checked="isCheckedDown" /><span
                    >{{ $t('unloadingTruck') }}</span
                  >
                </div>
                <div class="worker" v-if="isCheckedDown">
                  <div class="worker-loader">
                    <custom-select
                      @selectOptions="selectLoaders"
                      :clearable="clearable"
                      :placeholder="$t('qtyLoader')"
                      :icon-src="require('../assets/PP.svg')"
                      :options="loaders"
                      :class="{ 'valid-input': boolLoader === true }"
                    ></custom-select>
                    <span
                      class="countLoad"
                      v-if="
                        currentLoaders &&
                        currentLoaders <= 10 &&
                        currentLoaders !== 0
                      "
                      >Вант.</span
                    >
                  </div>
                  <div class="loaders-time">
                    <custom-select
                      class="after-timeWorker"
                      @selectOptions="selectLoaderTime"
                      :placeholder="$t('qtyHourLoader')"
                      :options="workerTimes"
                      :clearable="clearableDown"
                      :fontSize="'fontSize'"
                      :icon-src="require('../assets/clock.svg')"
                    ></custom-select>
                    <span
                      class="countLoad"
                      v-if="loaderTime && loaderTime <= 10 && loaderTime !== 1"
                      >Год.</span
                    >
                  </div>
                </div>
                <div class="hr"></div>
              </div>

              <div class="geolocation-inputs-cars">
                <div class="cars" @click="checkSelectCar">
                  <div class="select-car">
                    <car-select
                      @selectCars="selectedCar"
                      :placeholder="$t('selectTypeTruck')"
                      :typeCars="typeCars"
                      :icon-src="require('../assets/cars.svg')"
                      :class="{ 'valid-input': boolSelectCar === true }"
                    >
                    </car-select>
                  </div>
                </div>
                <!-- <hr /> -->
                <div class="hr"></div>
              </div>
              <div class="date">
                <div class="date-block" v-if="!boolTime">
                  <div class="time">
                    <div class="calendar">
                      <img :src="require('../assets/calendar.svg')" />
                      <VueDatePicker
                        :format="'dd/MM/yyyy'"
                        v-model="currentDate"
                        locale="ua"
                        :enable-time-picker="false"
                      ></VueDatePicker>
                    </div>
                    <div class="clock">
                      <img :src="require('../assets/clock.svg')" />
                      <VueTimepicker
                        :minute-interval="15"
                        hide-clear-button
                        auto-scroll
                        v-model="currentTime"
                      ></VueTimepicker>
                    </div>
                  </div>
                </div>
                <div class="hr"></div>
              </div>
              <div class="cost-of-services">
                <span class="cost">{{ $t("cost") }}</span>
                <span class="sum">~ {{ formatNumber(currentPrice) }} грн</span>
              </div>
              <div class="reg-application">
                <button @click="sendForm">
                  <span>{{ $t("application") }}</span>
                </button>
              </div>
            </div>

            <div class="wrapper-inputs" v-if="changeBtn === 'Переїзд по місту'">
              <div class="geolocation-inputs">
                <city-select
                  @selectOptions="selectPlace"
                  :citySelect="selectOptionsLogistick"
                  :icon-src="require('../assets/Line.svg')"
                  :class="{ 'valid-input': boolSelectPlace === true }"
                  :placeholder="$t('citySelect')"
                ></city-select>
                <!-- <hr /> -->
                <div class="hr"></div>
                <custom-input
                  v-model="inputValue"
                  :class="[{ 'valid-input': boolInput === true }]"
                  :placeholder="$t('nameLoadingTruck')"
                ></custom-input>
                <div class="hr"></div>
                <custom-input
                  v-model="inputValueDown"
                  :class="{ 'valid-input': downInput === true }"
                  :placeholder="$t('nameUnloadingTruck')"
                ></custom-input>
                <div class="hr"></div>
              </div>
              <!-- <button @click="clearFormLogistick">test</button> -->
              <div class="geolocation-inputs-cars">
                <div class="cars" @click="checkSelectCars">
                  <div class="select-car">
                    <car-select
                      @selectCars="selectedCarCity"
                      :placeholder="$t('selectTypeTruck')"
                      :typeCars="typeCarsCityInCity"
                      :icon-src="require('../assets/cars.svg')"
                      :class="{ 'valid-input': boolSelectCar === true }"
                    >
                    </car-select>
                    <div class="select-time-car">
                      <custom-select
                        @selectOptions="selectedCarTime"
                        :placeholder="$t('qtyHourLoader')"
                        :options="carTime"
                        :clearable="clearable"
                        :icon-src="require('../assets/clock.svg')"
                        :class="{ 'valid-input': boolCarTime === true }"
                      ></custom-select>
                      <span
                        class="countLoad"
                        v-if="
                          currentCarTimes &&
                          currentCarTimes <= 10 &&
                          currentCarTimes !== ''
                        "
                        >Год.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="hr"></div>
                <!-- <hr /> -->
              </div>
              <div class="date">
                <div class="date-block-logisticks" v-if="!boolTime">
                  <!-- <hr /> -->
                  <div class="time">
                    <div class="calendar">
                      <img :src="require('../assets/calendar.svg')" />
                      <VueDatePicker
                        :format="'dd/MM/yyyy'"
                        v-model="currentDate"
                        locale="ua"
                        :enable-time-picker="false"
                      ></VueDatePicker>
                    </div>

                    <div class="clock">
                      <img :src="require('../assets/clock.svg')" />
                      <VueTimepicker
                        hide-clear-button
                        auto-scroll
                        v-model="currentTime"
                        :minute-interval="15"
                      ></VueTimepicker>
                    </div>
                  </div>
                  <div class="hr"></div>
                  <div class="bool-worker">
                    <div class="after-worker">
                      <custom-select
                        @selectOptions="selectCityLoader"
                        :placeholder="$t('qtyLoader')"
                        :icon-src="require('../assets/PP.svg')"
                        :options="inCityLoader"
                        :clearable="clearable"
                        :class="{ 'valid-input': boolLoader === true }"
                      ></custom-select>
                      <span
                        class="countLoad"
                        v-if="cityLoader && cityLoader <= 10"
                        >Вант.</span
                      >
                    </div>
                    <div class="loader-time">
                      <custom-select
                        class="after-timeWorker"
                        @selectOptions="selectedTimeWork"
                        :placeholder="$t('qtyHourLoader')"
                        :options="timeWork"
                        :clearable="clearableTime"
                        :fontSize="'fontSize'"
                        :icon-src="require('../assets/clock.svg')"
                        :class="{ 'valid-input': boolWorkerTime === true }"
                      ></custom-select>
                      <span
                        class="countLoad"
                        v-if="
                          currentWorkTime &&
                          currentWorkTime <= 10 &&
                          currentWorkTime !== 1
                        "
                        >Год.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="hr"></div>
              </div>
              <!-- <hr /> -->
              <div class="cost-of-services">
                <span class="cost">{{ $t("cost") }}</span>
                <span class="sum">~ {{ formatNumber(currentPriceMv) }} грн</span>
              </div>
              <div class="reg-application">
                <button @click="sendForm">
                  <span>{{ $t("application") }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <service-list></service-list>
    <car-list></car-list>
    <block-info></block-info>
    <frequently-asked-questions></frequently-asked-questions>
    <ReviewsBlock />
    <subscribe-block></subscribe-block>
    <our-contacts></our-contacts>

    <!-- <modal-window
      @closeModal="closeModalWindow"
      :showModal="modalWindow"
      :sendInfo="sendInfo"
      :boolTime="boolTime"
      :showSucces="succesBlock"
      @succes="succesBlockView"
    ></modal-window>
    <ModalPlace
      @closeModal="closeModalWindowPlace"
      :showModal="modalPlace"
      :sendInfo="sendInfo"
      :boolTime="boolTime"
      :showSucces="succesBlock"
      @succes="succesBlockView"
    ></ModalPlace> -->
    <succes-block
      :showSucces="succesBlockVal"
      @closeSucces="closeSuccesBlock"
      @services="closeSuccesBlock"
    >
    </succes-block>
  </div>
</template>

<script>
// import SubHeader from "@/components/SubHeader.vue";
import ReviewsBlock from '../components/ReviewsBlock.vue'
import ServiceList from '../components/ServiceList.vue'
import CustomSelect from "@/components/UI/CustomSelect.vue";
import CustomInput from "../components/UI/CustomInput.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import VueTimepicker from "vue3-timepicker";
import "vue3-timepicker/dist/VueTimepicker.css";
import {
  ref,
  onMounted,
  onBeforeUnmount,
  computed,
  watch,
  onBeforeMount,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import BlockInfo from "../components/BlockInfo.vue";
import CarList from "../components/CarList.vue";
import FrequentlyAskedQuestions from "../components/FrequentlyAskedQuestions.vue";
import SubscribeBlock from "../components/SubscribeBlock.vue";
import OurContacts from "../components/OurContacts.vue";
import { useI18n } from "vue-i18n";
import CarSelect from "@/components/UI/CarSelect.vue";
import CitySelect from "@/components/UI/CitySelect.vue";
import city_info from "../../cityRange.json";
import ModalWindow from "../components/ModalWindow.vue";
import ModalPlace from "../components/ModalPlace.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import SuccesBlock from "../components/SuccesBlock.vue";
import numeral from 'numeral';
export default {
  name: "HomeView",
  components: {
    ServiceList,
    ReviewsBlock,
    CustomSelect,
    CustomInput,
    VueDatePicker,
    VueTimepicker,
    BlockInfo,
    CarList,
    FrequentlyAskedQuestions,
    SubscribeBlock,
    OurContacts,
    CarSelect,
    CitySelect,
    ModalWindow,
    SuccesBlock,
    ModalPlace,
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const isCheckedDown = ref(false);
    const inputValue = ref("");
    const modalPlace = ref(false);
    const currentPrice = ref(0);
    const clearableUp = ref(false);
    const clearableDown = ref(false);
    const currentPriceMv = ref(0);
    const cityRange = city_info;
    const modalWindow = ref(false);
    const succesBlockVal = ref(false);
    const clearableTime = ref(false);
    const succesBlock = ref(false);
    const closeModalWindow = () => {
      modalWindow.value = !modalWindow.value;
    };
    const closeModalWindowPlace = () => {
      modalPlace.value = !modalPlace.value;
    };
    const selectOptionsOrigin = ref([
      {
        city: "Вінниця",
        workerPrice: 250,
      },
      {
        city: "Дніпро",
        workerPrice: 180,
      },
      {
        city: "Донецьк",
        workerPrice: 300,
      },
      {
        city: "Житомир",
        workerPrice: 250,
      },
      {
        city: "Запоріжжя",
        workerPrice: 220,
      },
      {
        city: "Івано-Франківськ",
        workerPrice: 250,
      },
      {
        city: "Київ",
        workerPrice: 200,
      },
      {
        city: "Кропивницький",
        workerPrice: 240,
      },
      {
        city: "Луганськ",
        workerPrice: 300,
      },
      {
        city: "Луцьк",
        workerPrice: 250,
      },
      {
        city: "Львів",
        workerPrice: 220,
      },
      {
        city: "Миколаїв",
        workerPrice: 250,
      },
      {
        city: "Одеса",
        workerPrice: 220,
      },
      {
        city: "Полтава",
        workerPrice: 220,
      },
      {
        city: "Рівне",
        workerPrice: 250,
      },
      {
        city: "Суми",
        workerPrice: 250,
      },
      {
        city: "Тернопіль",
        workerPrice: 250,
      },
      {
        city: "Ужгород",
        workerPrice: 250,
      },
      {
        city: "Харків",
        workerPrice: 200,
      },
      {
        city: "Херсон",
        workerPrice: 250,
      },
      {
        city: "Хмельницький",
        workerPrice: 280,
      },
      {
        city: "Черкаси",
        workerPrice: 250,
      },
      {
        city: "Чернівці",
        workerPrice: 250,
      },
      {
        city: "Чернігів",
        workerPrice: 220,
      },
    ]);
    const selectOptions = ref([
      {
        city: "Вінниця",
        workerPrice: 250,
      },
      {
        city: "Дніпро",
        workerPrice: 180,
      },
      {
        city: "Донецьк",
        workerPrice: 300,
      },
      {
        city: "Житомир",
        workerPrice: 250,
      },
      {
        city: "Запоріжжя",
        workerPrice: 220,
      },
      {
        city: "Івано-Франківськ",
        workerPrice: 250,
      },
      {
        city: "Київ",
        workerPrice: 200,
      },
      {
        city: "Кропивницький",
        workerPrice: 240,
      },
      {
        city: "Луганськ",
        workerPrice: 300,
      },
      {
        city: "Луцьк",
        workerPrice: 250,
      },
      {
        city: "Львів",
        workerPrice: 220,
      },
      {
        city: "Миколаїв",
        workerPrice: 250,
      },
      {
        city: "Одеса",
        workerPrice: 220,
      },
      {
        city: "Полтава",
        workerPrice: 220,
      },
      {
        city: "Рівне",
        workerPrice: 250,
      },
      {
        city: "Суми",
        workerPrice: 250,
      },
      {
        city: "Тернопіль",
        workerPrice: 250,
      },
      {
        city: "Ужгород",
        workerPrice: 250,
      },
      {
        city: "Харків",
        workerPrice: 200,
      },
      {
        city: "Херсон",
        workerPrice: 250,
      },
      {
        city: "Хмельницький",
        workerPrice: 280,
      },
      {
        city: "Черкаси",
        workerPrice: 250,
      },
      {
        city: "Чернівці",
        workerPrice: 250,
      },
      {
        city: "Чернігів",
        workerPrice: 220,
      },
    ]);
    const selectOptionsTwo = ref([
      {
        city: "Вінниця",
        workerPrice: 250,
      },
      {
        city: "Дніпро",
        workerPrice: 180,
      },
      {
        city: "Донецьк",
        workerPrice: 300,
      },
      {
        city: "Житомир",
        workerPrice: 250,
      },
      {
        city: "Запоріжжя",
        workerPrice: 220,
      },
      {
        city: "Івано-Франківськ",
        workerPrice: 250,
      },
      {
        city: "Київ",
        workerPrice: 200,
      },
      {
        city: "Кропивницький",
        workerPrice: 240,
      },
      {
        city: "Луганськ",
        workerPrice: 300,
      },
      {
        city: "Луцьк",
        workerPrice: 250,
      },
      {
        city: "Львів",
        workerPrice: 220,
      },
      {
        city: "Миколаїв",
        workerPrice: 250,
      },
      {
        city: "Одеса",
        workerPrice: 220,
      },
      {
        city: "Полтава",
        workerPrice: 220,
      },
      {
        city: "Рівне",
        workerPrice: 250,
      },
      {
        city: "Суми",
        workerPrice: 250,
      },
      {
        city: "Тернопіль",
        workerPrice: 250,
      },
      {
        city: "Ужгород",
        workerPrice: 250,
      },
      {
        city: "Харків",
        workerPrice: 200,
      },
      {
        city: "Херсон",
        workerPrice: 250,
      },
      {
        city: "Хмельницький",
        workerPrice: 280,
      },
      {
        city: "Черкаси",
        workerPrice: 250,
      },
      {
        city: "Чернівці",
        workerPrice: 250,
      },
      {
        city: "Чернігів",
        workerPrice: 220,
      },
    ]);
    const selectOptionsLogistick = ref([
      {
        city: "Вінниця",
        workerPrice: 250,
      },
      {
        city: "Дніпро",
        workerPrice: 180,
      },
      {
        city: "Донецьк",
        workerPrice: 300,
      },
      {
        city: "Житомир",
        workerPrice: 250,
      },
      {
        city: "Запоріжжя",
        workerPrice: 220,
      },
      {
        city: "Івано-Франківськ",
        workerPrice: 250,
      },
      {
        city: "Київ",
        workerPrice: 200,
      },
      {
        city: "Кропивницький",
        workerPrice: 240,
      },
      {
        city: "Луганськ",
        workerPrice: 300,
      },
      {
        city: "Луцьк",
        workerPrice: 250,
      },
      {
        city: "Львів",
        workerPrice: 220,
      },
      {
        city: "Миколаїв",
        workerPrice: 250,
      },
      {
        city: "Одеса",
        workerPrice: 220,
      },
      {
        city: "Полтава",
        workerPrice: 220,
      },
      {
        city: "Рівне",
        workerPrice: 250,
      },
      {
        city: "Суми",
        workerPrice: 250,
      },
      {
        city: "Тернопіль",
        workerPrice: 250,
      },
      {
        city: "Ужгород",
        workerPrice: 250,
      },
      {
        city: "Харків",
        workerPrice: 200,
      },
      {
        city: "Херсон",
        workerPrice: 250,
      },
      {
        city: "Хмельницький",
        workerPrice: 280,
      },
      {
        city: "Черкаси",
        workerPrice: 250,
      },
      {
        city: "Чернівці",
        workerPrice: 250,
      },
      {
        city: "Чернігів",
        workerPrice: 220,
      },
    ]);
    const selectOptionsUp = ref([]);
    const selectOptionsDown = ref([]);
    const selectValUp = ref({
      city: "",
      workerPrice: "",
    });
    const selectValDown = ref({
      city: "",
      workerPrice: "",
    });
    const currentLoaders = ref("");
    const selectCar = ref("");
    const selectCarCityinCity = ref("");
    const isChecked = ref(false);
    const currentLoader = ref("");
    const cityLoader = ref("");
    const loadTime = ref("");
    const loaderTime = ref("");
    const currentWorkTime = ref("");
    const currentCarTimes = ref("");
    const place = ref("");
    const selectPlace = (value) => {
      // selectedCarCity
      typeCarsCityInCity.value = carsInfoPrice.value[value.city];
      if(selectCarCityinCity.value.price){
        const index = typeCarsCityInCity.value.findIndex((el) => el.text === selectCarCityinCity.value.text);
        if(index !== -1){
          selectCarCityinCity.value =   typeCarsCityInCity.value[index]
        }
      }
     
      place.value = value;
      boolSelectPlace.value = false;
    };
    const selectedOptionsUp = (selectUp) => {
      if (selectUp === "") {
        selectOptionsTwo.value = selectOptionsOrigin.value.slice();
        return;
      }
      selectValUp.value = selectUp;
      boolSelectValUp.value = false;
      const array = JSON.stringify(selectOptionsOrigin.value);
      selectOptionsTwo.value = JSON.parse(array);
      selectOptionsTwo.value = selectOptionsTwo.value.filter(
        (el) => el.city !== selectUp.city
      );
    };
    const selectedOptionsDown = (selectDown) => {
      if (selectDown === "") {
        selectOptions.value = selectOptionsOrigin.value.slice();
        return;
      }
      selectValDown.value = selectDown;
      boolSelectValDown.value = false;
      const array = JSON.stringify(selectOptionsOrigin.value);
      selectOptions.value = JSON.parse(array);
      selectOptions.value = selectOptions.value.filter(
        (el) => el.city !== selectDown.city
      );
    };
    const boolTime = ref(false);
    const checkSelectCar = () => {
      if (typeCars.value.length === 0) {
        toast.error("Оберіть Місто", {
          autoClose: 1000,
        });
      }
    };

    const checkSelectCars = () => {
      if (typeCarsCityInCity.value.length === 0) {
        toast.error("Оберіть Місто", {
          autoClose: 1000,
        });
      }
    };
    const selectedCar = (selectedCar) => {
      selectCar.value = selectedCar;
      boolSelectCar.value = false;
    };
    const selectedCarCity = (selectedCar) => {
      clearable.value = false;
     
      selectCarCityinCity.value = selectedCar;
      if (selectedCar.text === "Не вказано" && selectedCar.price === 0) {
        clearable.value = true;
        currentCarTimes.value = "";
      }

      boolSelectCar.value = false;
    };
    const sumPrice = computed(() => {
      if (
        selectValUp.value === selectValDown.value &&
        selectValUp.value != "" &&
        selectValDown.value != ""
      ) {
        return selectOptions.value.workerPrice;
      } else {
        return "selectOptions.value.workerPrice";
      }
    });

    const { t } = useI18n();
 
    const viewCars = ref(false);
    const typeCars = ref([
      {
        text: "Не вказано",
        price: 0,
      },
      {
        text: "Авто до 1,2 т (10 м3)",
        price: 15,
      },
      {
        text: "Авто до 1,5 т (16 м3)",
        price: 17,
      },
      {
        text: "Авто до 2 т (20 м3)",
        price: 18,
      },
      {
        text: "Авто до 3 т (24 м3)",
        price: 21,
      },
      {
        text: "Авто до 5 т (36 м3)",
        price: 23,
      },
      {
        text: "Авто до 7 т (45 м3)",
        price: 25,
      },
      {
        text: "Авто до 10 т (50 м3)",
        price: 28,
      },
      {
        text: "Авто до 20 т (від 82 м3)",
        price: 41,
      },
    ]);
    const typeCarsCityInCity = ref([
      {
        text: "Не вказано",
        price: 0,
      },
      {
        text: "Авто до 1,2 т (10 м3)",
        price: 15,
      },
      {
        text: "Авто до 1,5 т (16 м3)",
        price: 17,
      },
      {
        text: "Авто до 2 т (20 м3)",
        price: 18,
      },
      {
        text: "Авто до 3 т (24 м3)",
        price: 21,
      },
      {
        text: "Авто до 5 т (36 м3)",
        price: 23,
      },
      {
        text: "Авто до 7 т (45 м3)",
        price: 25,
      },
      {
        text: "Авто до 10 т (50 м3)",
        price: 28,
      },
      {
        text: "Авто до 20 т (від 82 м3)",
        price: 41,
      },
    ]);
    const carsInfoPrice = ref({
      range: [
        {
          text: "Не вказано",
          price: 0,
        },
        {
          text: "Авто до 1,2 т (10 м3)",
          price: 15,
        },
        {
          text: "Авто до 1,5 т (16 м3)",
          price: 17,
        },
        {
          text: "Авто до 2 т (20 м3)",
          price: 18,
        },
        {
          text: "Авто до 3 т (24 м3)",
          price: 21,
        },
        {
          text: "Авто до 5 т (36 м3)",
          price: 23,
        },
        {
          text: "Авто до 7 т (45 м3)",
          price: 25,
        },
        {
          text: "Авто до 10 т (50 м3)",
          price: 28,
        },
        {
          text: "Авто до 20 т (від 82 м3)",
          price: 41,
        },
      ],
      Вінниця: [
        {
          text: "Не вказано",
          price: 0,
        },
        {
          text: "Авто до 1,2 т (10 м3)",
          price: 500,
        },
        {
          text: "Авто до 1,5 т (16 м3)",
          price: 550,
        },
        {
          text: "Авто до 2 т (20 м3)",
          price: 600,
        },
        {
          text: "Авто до 3 т (24 м3)",
          price: 700,
        },
        {
          text: "Авто до 5 т (36 м3)",
          price: 850,
        },
        {
          text: "Авто до 7 т (45 м3)",
          price: 900,
        },
        {
          text: "Авто до 10 т (50 м3)",
          price: 1000,
        },
        {
          text: "Авто до 20 т (від 82 м3)",
          price: 1250,
        },
      ],

      Дніпро: [
        {
          text: "Не вказано",
          price: 0,
        },
        {
          text: "Авто до 1,2 т (10 м3)",
          price: 350,
        },
        {
          text: "Авто до 1,5 т (16 м3)",
          price: 400,
        },
        {
          text: "Авто до 2 т (20 м3)",
          price: 450,
        },
        {
          text: "Авто до 3 т (24 м3)",
          price: 550,
        },
        {
          text: "Авто до 5 т (36 м3)",
          price: 700,
        },
        {
          text: "Авто до 7 т (45 м3)",
          price: 800,
        },
        {
          text: "Авто до 10 т (50 м3)",
          price: 900,
        },
        {
          text: "Авто до 20 т (від 82 м3)",
          price: 1100,
        },
      ],

      Донецьк: [
        {
          text: "Не вказано",
          price: 0,
        },
        {
          text: "Авто до 1,2 т (10 м3)",
          price: 600,
        },
        {
          text: "Авто до 1,5 т (16 м3)",
          price: 700,
        },
        {
          text: "Авто до 2 т (20 м3)",
          price: 750,
        },
        {
          text: "Авто до 3 т (24 м3)",
          price: 800,
        },
        {
          text: "Авто до 5 т (36 м3)",
          price: 1000,
        },
        {
          text: "Авто до 7 т (45 м3)",
          price: 1100,
        },
        {
          text: "Авто до 10 т (50 м3)",
          price: 1300,
        },
        {
          text: "Авто до 20 т (від 82 м3)",
          price: 1500,
        },
      ],

      Житомир: [
        {
          text: "Не вказано",
          price: 0,
        },
        {
          text: "Авто до 1,2 т (10 м3)",
          price: 500,
        },
        {
          text: "Авто до 1,5 т (16 м3)",
          price: 550,
        },
        {
          text: "Авто до 2 т (20 м3)",
          price: 600,
        },
        {
          text: "Авто до 3 т (24 м3)",
          price: 700,
        },
        {
          text: "Авто до 5 т (36 м3)",
          price: 850,
        },
        {
          text: "Авто до 7 т (45 м3)",
          price: 900,
        },
        {
          text: "Авто до 10 т (50 м3)",
          price: 1000,
        },
        {
          text: "Авто до 20 т (від 82 м3)",
          price: 1250,
        },
      ],

      Запоріжжя: [
        {
          text: "Не вказано",
          price: 0,
        },
        {
          text: "Авто до 1,2 т (10 м3)",
          price: 500,
        },
        {
          text: "Авто до 1,5 т (16 м3)",
          price: 550,
        },
        {
          text: "Авто до 2 т (20 м3)",
          price: 600,
        },
        {
          text: "Авто до 3 т (24 м3)",
          price: 700,
        },
        {
          text: "Авто до 5 т (36 м3)",
          price: 850,
        },
        {
          text: "Авто до 7 т (45 м3)",
          price: 900,
        },
        {
          text: "Авто до 10 т (50 м3)",
          price: 1000,
        },
        {
          text: "Авто до 20 т (від 82 м3)",
          price: 1250,
        },
      ],

      "Івано-Франківськ": [
        {
          text: "Не вказано",
          price: 0,
        },
        {
          text: "Авто до 1,2 т (10 м3)",
          price: 500,
        },
        {
          text: "Авто до 1,5 т (16 м3)",
          price: 550,
        },
        {
          text: "Авто до 2 т (20 м3)",
          price: 600,
        },
        {
          text: "Авто до 3 т (24 м3)",
          price: 700,
        },
        {
          text: "Авто до 5 т (36 м3)",
          price: 850,
        },
        {
          text: "Авто до 7 т (45 м3)",
          price: 900,
        },
        {
          text: "Авто до 10 т (50 м3)",
          price: 1000,
        },
        {
          text: "Авто до 20 т (від 82 м3)",
          price: 1250,
        },
      ],

      Київ: [
        {
          text: "Не вказано",
          price: 0,
        },
        {
          text: "Авто до 1,2 т (10 м3)",
          price: 500,
        },
        {
          text: "Авто до 1,5 т (16 м3)",
          price: 550,
        },
        {
          text: "Авто до 2 т (20 м3)",
          price: 600,
        },
        {
          text: "Авто до 3 т (24 м3)",
          price: 700,
        },
        {
          text: "Авто до 5 т (36 м3)",
          price: 850,
        },
        {
          text: "Авто до 7 т (45 м3)",
          price: 900,
        },
        {
          text: "Авто до 10 т (50 м3)",
          price: 1000,
        },
        {
          text: "Авто до 20 т (від 82 м3)",
          price: 1250,
        },
      ],

      Кропивницький: [
        {
          text: "Не вказано",
          price: 0,
        },
        {
          text: "Авто до 1,2 т (10 м3)",
          price: 500,
        },
        {
          text: "Авто до 1,5 т (16 м3)",
          price: 550,
        },
        {
          text: "Авто до 2 т (20 м3)",
          price: 600,
        },
        {
          text: "Авто до 3 т (24 м3)",
          price: 700,
        },
        {
          text: "Авто до 5 т (36 м3)",
          price: 850,
        },
        {
          text: "Авто до 7 т (45 м3)",
          price: 900,
        },
        {
          text: "Авто до 10 т (50 м3)",
          price: 1000,
        },
        {
          text: "Авто до 20 т (від 82 м3)",
          price: 1250,
        },
      ],

      Луганськ: [
        {
          text: "Не вказано",
          price: 0,
        },
        {
          text: "Авто до 1,2 т (10 м3)",
          price: 600,
        },
        {
          text: "Авто до 1,5 т (16 м3)",
          price: 700,
        },
        {
          text: "Авто до 2 т (20 м3)",
          price: 750,
        },
        {
          text: "Авто до 3 т (24 м3)",
          price: 800,
        },
        {
          text: "Авто до 5 т (36 м3)",
          price: 1000,
        },
        {
          text: "Авто до 7 т (45 м3)",
          price: 1100,
        },
        {
          text: "Авто до 10 т (50 м3)",
          price: 1300,
        },
        {
          text: "Авто до 20 т (від 82 м3)",
          price: 1500,
        },
      ],

      Луцьк: [
        {
          text: "Не вказано",
          price: 0,
        },
        {
          text: "Авто до 1,2 т (10 м3)",
          price: 500,
        },
        {
          text: "Авто до 1,5 т (16 м3)",
          price: 550,
        },
        {
          text: "Авто до 2 т (20 м3)",
          price: 600,
        },
        {
          text: "Авто до 3 т (24 м3)",
          price: 700,
        },
        {
          text: "Авто до 5 т (36 м3)",
          price: 850,
        },
        {
          text: "Авто до 7 т (45 м3)",
          price: 900,
        },
        {
          text: "Авто до 10 т (50 м3)",
          price: 1000,
        },
        {
          text: "Авто до 20 т (від 82 м3)",
          price: 1250,
        },
      ],

      Львів: [
        {
          text: "Не вказано",
          price: 0,
        },
        {
          text: "Авто до 1,2 т (10 м3)",
          price: 500,
        },
        {
          text: "Авто до 1,5 т (16 м3)",
          price: 550,
        },
        {
          text: "Авто до 2 т (20 м3)",
          price: 600,
        },
        {
          text: "Авто до 3 т (24 м3)",
          price: 700,
        },
        {
          text: "Авто до 5 т (36 м3)",
          price: 850,
        },
        {
          text: "Авто до 7 т (45 м3)",
          price: 900,
        },
        {
          text: "Авто до 10 т (50 м3)",
          price: 1000,
        },
        {
          text: "Авто до 20 т (від 82 м3)",
          price: 1250,
        },
      ],

      Миколаїв: [
        {
          text: "Не вказано",
          price: 0,
        },
        {
          text: "Авто до 1,2 т (10 м3)",
          price: 550,
        },
        {
          text: "Авто до 1,5 т (16 м3)",
          price: 600,
        },
        {
          text: "Авто до 2 т (20 м3)",
          price: 650,
        },
        {
          text: "Авто до 3 т (24 м3)",
          price: 750,
        },
        {
          text: "Авто до 5 т (36 м3)",
          price: 850,
        },
        {
          text: "Авто до 7 т (45 м3)",
          price: 950,
        },
        {
          text: "Авто до 10 т (50 м3)",
          price: 1010,
        },
        {
          text: "Авто до 20 т (від 82 м3)",
          price: 1300,
        },
      ],

      Одеса: [
        {
          text: "Не вказано",
          price: 0,
        },
        {
          text: "Авто до 1,2 т (10 м3)",
          price: 500,
        },
        {
          text: "Авто до 1,5 т (16 м3)",
          price: 550,
        },
        {
          text: "Авто до 2 т (20 м3)",
          price: 600,
        },
        {
          text: "Авто до 3 т (24 м3)",
          price: 700,
        },
        {
          text: "Авто до 5 т (36 м3)",
          price: 850,
        },
        {
          text: "Авто до 7 т (45 м3)",
          price: 900,
        },
        {
          text: "Авто до 10 т (50 м3)",
          price: 1000,
        },
        {
          text: "Авто до 20 т (від 82 м3)",
          price: 1250,
        },
      ],

      Полтава: [
        {
          text: "Не вказано",
          price: 0,
        },
        {
          text: "Авто до 1,2 т (10 м3)",
          price: 500,
        },
        {
          text: "Авто до 1,5 т (16 м3)",
          price: 550,
        },
        {
          text: "Авто до 2 т (20 м3)",
          price: 600,
        },
        {
          text: "Авто до 3 т (24 м3)",
          price: 700,
        },
        {
          text: "Авто до 5 т (36 м3)",
          price: 850,
        },
        {
          text: "Авто до 7 т (45 м3)",
          price: 900,
        },
        {
          text: "Авто до 10 т (50 м3)",
          price: 1000,
        },
        {
          text: "Авто до 20 т (від 82 м3)",
          price: 1250,
        },
      ],

      Рівне: [
        {
          text: "Не вказано",
          price: 0,
        },
        {
          text: "Авто до 1,2 т (10 м3)",
          price: 500,
        },
        {
          text: "Авто до 1,5 т (16 м3)",
          price: 550,
        },
        {
          text: "Авто до 2 т (20 м3)",
          price: 600,
        },
        {
          text: "Авто до 3 т (24 м3)",
          price: 700,
        },
        {
          text: "Авто до 5 т (36 м3)",
          price: 850,
        },
        {
          text: "Авто до 7 т (45 м3)",
          price: 900,
        },
        {
          text: "Авто до 10 т (50 м3)",
          price: 1000,
        },
        {
          text: "Авто до 20 т (від 82 м3)",
          price: 1250,
        },
      ],

      Суми: [
        {
          text: "Не вказано",
          price: 0,
        },
        {
          text: "Авто до 1,2 т (10 м3)",
          price: 550,
        },
        {
          text: "Авто до 1,5 т (16 м3)",
          price: 600,
        },
        {
          text: "Авто до 2 т (20 м3)",
          price: 650,
        },
        {
          text: "Авто до 3 т (24 м3)",
          price: 750,
        },
        {
          text: "Авто до 5 т (36 м3)",
          price: 850,
        },
        {
          text: "Авто до 7 т (45 м3)",
          price: 950,
        },
        {
          text: "Авто до 10 т (50 м3)",
          price: 1010,
        },
        {
          text: "Авто до 20 т (від 82 м3)",
          price: 1300,
        },
      ],

      Тернопіль: [
        {
          text: "Не вказано",
          price: 0,
        },
        {
          text: "Авто до 1,2 т (10 м3)",
          price: 500,
        },
        {
          text: "Авто до 1,5 т (16 м3)",
          price: 550,
        },
        {
          text: "Авто до 2 т (20 м3)",
          price: 600,
        },
        {
          text: "Авто до 3 т (24 м3)",
          price: 700,
        },
        {
          text: "Авто до 5 т (36 м3)",
          price: 850,
        },
        {
          text: "Авто до 7 т (45 м3)",
          price: 900,
        },
        {
          text: "Авто до 10 т (50 м3)",
          price: 1000,
        },
        {
          text: "Авто до 20 т (від 82 м3)",
          price: 1250,
        },
      ],

      Ужгород: [
        {
          text: "Не вказано",
          price: 0,
        },
        {
          text: "Авто до 1,2 т (10 м3)",
          price: 550,
        },
        {
          text: "Авто до 1,5 т (16 м3)",
          price: 600,
        },
        {
          text: "Авто до 2 т (20 м3)",
          price: 650,
        },
        {
          text: "Авто до 3 т (24 м3)",
          price: 750,
        },
        {
          text: "Авто до 5 т (36 м3)",
          price: 850,
        },
        {
          text: "Авто до 7 т (45 м3)",
          price: 950,
        },
        {
          text: "Авто до 10 т (50 м3)",
          price: 1010,
        },
        {
          text: "Авто до 20 т (від 82 м3)",
          price: 1300,
        },
      ],

      Харків: [
        {
          text: "Не вказано",
          price: 0,
        },
        {
          text: "Авто до 1,2 т (10 м3)",
          price: 550,
        },
        {
          text: "Авто до 1,5 т (16 м3)",
          price: 600,
        },
        {
          text: "Авто до 2 т (20 м3)",
          price: 650,
        },
        {
          text: "Авто до 3 т (24 м3)",
          price: 750,
        },
        {
          text: "Авто до 5 т (36 м3)",
          price: 850,
        },
        {
          text: "Авто до 7 т (45 м3)",
          price: 950,
        },
        {
          text: "Авто до 10 т (50 м3)",
          price: 1010,
        },
        {
          text: "Авто до 20 т (від 82 м3)",
          price: 1300,
        },
      ],

      Херсон: [
        {
          text: "Не вказано",
          price: 0,
        },
        {
          text: "Авто до 1,2 т (10 м3)",
          price: 550,
        },
        {
          text: "Авто до 1,5 т (16 м3)",
          price: 600,
        },
        {
          text: "Авто до 2 т (20 м3)",
          price: 650,
        },
        {
          text: "Авто до 3 т (24 м3)",
          price: 750,
        },
        {
          text: "Авто до 5 т (36 м3)",
          price: 850,
        },
        {
          text: "Авто до 7 т (45 м3)",
          price: 950,
        },
        {
          text: "Авто до 10 т (50 м3)",
          price: 1010,
        },
        {
          text: "Авто до 20 т (від 82 м3)",
          price: 1300,
        },
      ],

      Хмельницький: [
        {
          text: "Не вказано",
          price: 0,
        },
        {
          text: "Авто до 1,2 т (10 м3)",
          price: 550,
        },
        {
          text: "Авто до 1,5 т (16 м3)",
          price: 600,
        },
        {
          text: "Авто до 2 т (20 м3)",
          price: 650,
        },
        {
          text: "Авто до 3 т (24 м3)",
          price: 750,
        },
        {
          text: "Авто до 5 т (36 м3)",
          price: 850,
        },
        {
          text: "Авто до 7 т (45 м3)",
          price: 950,
        },
        {
          text: "Авто до 10 т (50 м3)",
          price: 1010,
        },
        {
          text: "Авто до 20 т (від 82 м3)",
          price: 1300,
        },
      ],

      Черкаси: [
        {
          text: "Не вказано",
          price: 0,
        },
        {
          text: "Авто до 1,2 т (10 м3)",
          price: 500,
        },
        {
          text: "Авто до 1,5 т (16 м3)",
          price: 550,
        },
        {
          text: "Авто до 2 т (20 м3)",
          price: 600,
        },
        {
          text: "Авто до 3 т (24 м3)",
          price: 700,
        },
        {
          text: "Авто до 5 т (36 м3)",
          price: 850,
        },
        {
          text: "Авто до 7 т (45 м3)",
          price: 900,
        },
        {
          text: "Авто до 10 т (50 м3)",
          price: 1000,
        },
        {
          text: "Авто до 20 т (від 82 м3)",
          price: 1250,
        },
      ],

      Чернівці: [
        {
          text: "Не вказано",
          price: 0,
        },
        {
          text: "Авто до 1,2 т (10 м3)",
          price: 550,
        },
        {
          text: "Авто до 1,5 т (16 м3)",
          price: 600,
        },
        {
          text: "Авто до 2 т (20 м3)",
          price: 650,
        },
        {
          text: "Авто до 3 т (24 м3)",
          price: 750,
        },
        {
          text: "Авто до 5 т (36 м3)",
          price: 850,
        },
        {
          text: "Авто до 7 т (45 м3)",
          price: 950,
        },
        {
          text: "Авто до 10 т (50 м3)",
          price: 1010,
        },
        {
          text: "Авто до 20 т (від 82 м3)",
          price: 1300,
        },
      ],

      Чернігів: [
        {
          text: "Не вказано",
          price: 0,
        },
        {
          text: "Авто до 1,2 т (10 м3)",
          price: 500,
        },
        {
          text: "Авто до 1,5 т (16 м3)",
          price: 550,
        },
        {
          text: "Авто до 2 т (20 м3)",
          price: 600,
        },
        {
          text: "Авто до 3 т (24 м3)",
          price: 700,
        },
        {
          text: "Авто до 5 т (36 м3)",
          price: 850,
        },
        {
          text: "Авто до 7 т (45 м3)",
          price: 900,
        },
        {
          text: "Авто до 10 т (50 м3)",
          price: 1000,
        },
        {
          text: "Авто до 20 т (від 82 м3)",
          price: 1250,
        },
      ],
    });
    const loader = ref(["Не вказано", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
    const loaders = ref(["Не вказано", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
    const inCityLoader = ref(["Не вказано", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
    const inputValueDown = ref("");
    const downInput = ref(false);
    const boolInput = ref(false);
    const boolSelectPlace = ref(false);
    const boolSelectValUp = ref(false);
    const boolSelectValDown = ref(false);
    const boolSelectCar = ref(false);
    const boolLoader = ref(false);
    const boolWorkerTime = ref(false);
    const boolCarTime = ref(false);
    const sendInfo = ref({});
    const sendForm = () => {
      if (changeBtn.value == "Переїзд по місту") {
        if (place.value === "") {
          boolSelectPlace.value = true;
        }
        if (place.value !== "") {
          boolSelectPlace.value = false;
        }
        if (inputValue.value === "") {
          boolInput.value = true;
        }
        if (inputValue.value !== "") {
          boolInput.value = false;
        }
        if (cityLoader.value === "") {
          boolLoader.value = true;
        }
        if (cityLoader.value !== "") {
          boolLoader.value = false;
        }
        if (currentWorkTime.value === "") {
          boolWorkerTime.value = true;
        }
        if (currentWorkTime.value !== "") {
          boolWorkerTime.value = false;
        }
        if (inputValueDown.value === "") {
          downInput.value = true;
        }
        if (inputValueDown.value !== "") {
          downInput.value = false;
        }
        if (
          boolInput.value == false ||
          boolSelectPlace.value == false ||
          boolWorkerTime.value == false ||
          downInput.value == false ||
          boolLoader.value == false
        ) {
          modalPlace.value = true;
        }
        if (
          boolInput.value == true ||
          boolSelectPlace.value == true ||
          boolWorkerTime.value == true ||
          downInput.value == true ||
          boolLoader.value == true
        ) {
          modalPlace.value = false;
        }

        const inputDate = new Date(currentDate.value);
        const day = String(inputDate.getDate()).padStart(2, "0");
        const month = String(inputDate.getMonth() + 1).padStart(2, "0");
        const year = inputDate.getFullYear();
        const formattedDate = `${day}/${month}/${year}`;
        sendInfo.value.town = place.value.city;
        sendInfo.value.street = inputValue.value;
        sendInfo.value.streetDown = inputValueDown.value;
        sendInfo.value.car = selectCarCityinCity.value.text;
        sendInfo.value.loader = cityLoader.value;
        sendInfo.value.price = currentPriceMv.value;
        sendInfo.value.data = formattedDate;
        sendInfo.value.time = currentTime.value;
        sendInfo.value.workTime = currentWorkTime.value;
        sendInfo.value.carTime = currentCarTimes.value;
        if (selectCarCityinCity.value === "") {
          sendInfo.value.car = "Не вказано";
        }
        if (currentCarTimes.value === "") {
          sendInfo.value.carTime = "Не вказано";
        }
      }
      if (changeBtn.value !== "Переїзд по місту") {
        if (inputValue.value === "") {
          boolInput.value = true;
        }
        if (inputValueDown.value === "") {
          downInput.value = true;
        }
        if (inputValue.value !== "") {
          boolInput.value = false;
        }
        if (inputValueDown.value !== "") {
          downInput.value = false;
        }
        if (selectValUp.value.city === "") {
          boolSelectValUp.value = true;
        }
        if (selectValDown.value.city === "") {
          boolSelectValDown.value = true;
        }
        if (selectValUp.value.city !== "") {
          boolSelectValUp.value = false;
        }
        if (selectValDown.value.city !== "") {
          boolSelectValDown.value = false;
        }

        if (selectCar.value === "") {
          boolSelectCar.value = true;
        }
        if (selectCar.value !== "") {
          boolSelectCar.value = false;
        }

        if (
          boolInput.value == false ||
          downInput.value == false ||
          boolSelectValUp.value == false ||
          boolSelectValDown.value == false ||
          boolSelectCar.value == false
        ) {
          modalWindow.value = true;
        }
        if (
          boolInput.value == true ||
          downInput.value == true ||
          boolSelectValUp.value == true ||
          boolSelectValDown.value == true ||
          boolSelectCar.value == true
        ) {
          modalWindow.value = false;
        }
        const inputDate = new Date(currentDate.value);
        const day = String(inputDate.getDate()).padStart(2, "0");
        const month = String(inputDate.getMonth() + 1).padStart(2, "0");
        const year = inputDate.getFullYear();
        const formattedDate = `${day}/${month}/${year}`;
        sendInfo.value.town = selectValUp.value.city;
        sendInfo.value.townTo = selectValDown.value.city;
        sendInfo.value.street = inputValue.value;
        sendInfo.value.streetTo = inputValueDown.value;
        sendInfo.value.car = selectCar.value.text;
        sendInfo.value.loader = currentLoader.value;
        sendInfo.value.loaders = currentLoaders.value;
        sendInfo.value.price = currentPrice.value;
        sendInfo.value.data = formattedDate;
        sendInfo.value.time = currentTime.value;
        sendInfo.value.workTime = currentWorkTime.value;
        sendInfo.value.carTime = currentCarTimes.value;
        sendInfo.value.timeLoad = loadTime.value;
        sendInfo.value.times = loaderTime.value;
      }
    };

    const timeWork = ref(["Не вказано", 2, 3, 4, 5, 6, 7, 8, 9, 10]);
    const workTimes = ref(["Не вказано", 2, 3, 4, 5, 6, 7, 8, 9, 10]);
    const workerTimes = ref(["Не вказано", 2, 3, 4, 5, 6, 7, 8, 9, 10]);
    const carTime = ref(["Не вказано", 2, 3, 4, 5, 6, 7, 8, 9, 10]);
    const currentDate = ref(new Date());
    const currentTime = ref("");
    const changeBtn = ref("Переїзд по Україні");
    const category = ref("Переїзд по Україні", "Переїзд по місту");
    const clickChangeBtn = (choise) => {
      changeBtn.value = choise;
      cityLoader.value = "";
      currentCarTimes.value = "";
      currentWorkTime.value = "";
      viewCars.value = false;
      const array = JSON.stringify(selectOptionsOrigin.value);
      selectValUp.value.city = "";
      selectValUp.value.workerPrice = "";
      selectValDown.value.city = "";
      selectValDown.value.workerPrice = "";
      selectOptions.value = JSON.parse(array);
      selectOptionsTwo.value = JSON.parse(array);
      inputValue.value = "";
      inputValueDown.value = "";
      selectCar.value = []
    };
    const clickChangeBtnMv = (choise) => {
      changeBtn.value = choise;
      currentLoader.value = "";
      currentLoaders.value = "";
      isChecked.value = false;
      isCheckedDown.value = false;
      viewCars.value = false;
      inputValue.value = "";
      inputValueDown.value = "";
      if (place.value === "") {
        return;
      }
      if (place.value.city !== "") {
        const array = JSON.stringify(selectOptionsOrigin.value);
        place.value.city = "";
        place.value.workerPrice = "";
        selectOptionsLogistick.value = JSON.parse(array);
      }
    };
    const selectedLoader = (selectedLoader) => {
      currentLoader.value = selectedLoader;
      boolLoader.value = false;
      clearableUp.value = false;
      if (selectedLoader == "Не вказано") {
        clearableUp.value = true;
        loadTime.value = "";
      }
    };
    const selectLoaders = (selectedLoader) => {
      currentLoaders.value = selectedLoader;
      boolLoader.value = false;
      clearableDown.value = false;
      if (selectedLoader == "Не вказано") {
        clearableDown.value = true;
        loaderTime.value = "";
      }
    };

    const selectCityLoader = (selectedLoader) => {
      cityLoader.value = selectedLoader;
      boolLoader.value = false;
      clearable.value = false;
      if (selectedLoader == "Не вказано") {
        clearableTime.value = true;
        currentWorkTime.value = "";
      }
    };

    const selectedTimeWork = (currentWorkTimes) => {
      currentWorkTime.value = currentWorkTimes;
      boolWorkerTime.value = false;
      clearable.value = false;
      clearableTime.value = false;
    };
    const selectLoadTime = (loadTimes) => {
      loadTime.value = loadTimes;
      clearableUp.value = false;
    };
    const selectLoaderTime = (loadersTimes) => {
      loaderTime.value = loadersTimes;
      clearableDown.value = false;
    };

    const selectedCarTime = (selectedCarTime) => {
      clearable.value = false;
      currentCarTimes.value = selectedCarTime;
      boolCarTime.value = false;
    };

    const images = {
      default: require("../assets/Photo.jpg"),
      alternative: require("../assets/Rectangle 2729.jpg"),
    };
    const currentImage = ref(images.default);
    const handleResize = () => {
      if (window.innerWidth < 770) {
        currentImage.value = images.alternative;
      } else {
        currentImage.value = images.default;
      }
    };
    const succesBlockView = () => {
      modalWindow.value = false;
      modalPlace.value = false;
      succesBlockVal.value = true;
      clearForm();
    };
    const clearable = ref(false);
    const clearForm = () => {
      if (changeBtn.value !== "Переїзд по місту") {
        const array = JSON.stringify(selectOptionsOrigin.value);
        const arrayCar = JSON.stringify(typeCars.value);
        const arr = JSON.stringify(carsInfoPrice.value.range);
        selectValUp.value.city = "";
        selectValUp.value.workerPrice = "";
        selectValDown.value.city = "";
        selectValDown.value.workerPrice = "";
        inputValue.value = "";
        currentPrice.value = 0
        inputValueDown.value = "";
        selectOptions.value = JSON.parse(array);
        selectOptionsTwo.value = JSON.parse(array);
        selectCar.value.text = "";
        selectCar.value.price = "";
        carsInfoPrice.value.range = JSON.parse(arrayCar);
        typeCars.value = JSON.parse(arr);
        currentLoader.value = "";
        clearable.value = true;
        isChecked.value = false;
        isCheckedDown.value = false;
      }
    };
    const closeSuccesBlock = () => {
      succesBlockVal.value = !succesBlockVal.value;
      clearFormLogistick();
    };
    const clearFormLogistick = () => {
      if (changeBtn.value === "Переїзд по місту") {
        const array = JSON.stringify(selectOptionsOrigin.value);
        const arrayCar = JSON.stringify(typeCarsCityInCity.value);
        const arr = JSON.stringify(carsInfoPrice.value.range);
        place.value.city = "";
        place.value.workerPrice = "";
        inputValue.value = "";
        inputValueDown.value = "";
        selectOptionsLogistick.value = JSON.parse(array);
        selectCarCityinCity.value.text = "";
        selectCarCityinCity.value.price = "";
        carsInfoPrice.value.range = JSON.parse(arrayCar);
        typeCarsCityInCity.value = JSON.parse(arr);
        cityLoader.value = "";
        currentWorkTime.value = "";
        currentCarTimes.value = "";
        clearable.value = true;
      }
    };
    onMounted(() => {
      handleResize();
      window.addEventListener("resize", handleResize);

      setTimeout(() => {
        const container = document.querySelector(".kzANES");
        const nestedDivs = container.querySelectorAll("div div > a:last-child");
        nestedDivs.forEach((div) => {
          div.remove();
        });
      }, 4000);
    });
    onBeforeUnmount(() => {
      window.removeEventListener("resize", handleResize);
    });

    const setCurrentPrice = () => {
      currentPriceMv.value = 0;
      currentPrice.value = 0;
      typeCars.value = [];
      typeCarsCityInCity.value = [];

      if (changeBtn.value === "Переїзд по місту") {
        currentPriceMv.value = 0;
        if (place.value.city) {
          typeCarsCityInCity.value = carsInfoPrice.value[place.value.city];
         
          currentPriceMv.value =
            Number(selectCarCityinCity.value.price) *
              Number(currentCarTimes.value) +
            Number(place.value.workerPrice) *
              Number(cityLoader.value) *
              Number(currentWorkTime.value);
             
          if (
            selectCarCityinCity.value === "" &&
            currentCarTimes.value === ""
          ) {
            currentPriceMv.value =
              Number(place.value.workerPrice) *
              Number(cityLoader.value) *
              Number(currentWorkTime.value);
          }
          if (isNaN(currentPriceMv.value)) {
            currentPriceMv.value = 0;
          }
          return;
        }
      }

      if (changeBtn.value !== "Переїзд по місту") {
        currentPrice.value = 0;

        if (
          selectValUp.value.city !== selectValDown.value.city &&
          selectValUp.value.city !== "" &&
          selectValDown.value.city !== ""
        ) {
          boolTime.value = false;
          typeCars.value = carsInfoPrice.value.range;
          let range = 0;
          const cityInfo = cityRange.city_info.findIndex(
            (el) =>
              el.town == selectValUp.value.city &&
              el.town_to == selectValDown.value.city
          );
          const index = cityRange.city_info.findIndex(
            (el) =>
              el.town_to == selectValUp.value.city &&
              el.town == selectValDown.value.city
          );
          if (cityInfo !== -1) {
            range = cityRange.city_info[cityInfo].range;
          }
          if (index !== -1) {
            range = cityRange.city_info[index].range;
          }

          currentPrice.value = range * selectCar.value.price;
          if (isChecked.value && currentLoader.value) {
            currentPrice.value +=
              Number(selectValUp.value.workerPrice) *
              Number(currentLoader.value) *
              Number(loadTime.value);
          }
          if (isCheckedDown.value && currentLoaders.value) {
            currentPrice.value +=
              Number(selectValDown.value.workerPrice) *
              Number(currentLoaders.value) *
              Number(loaderTime.value);
          }

          if (isNaN(currentPrice.value)) {
            currentPrice.value = 0;
          }
          return;
        }
      }
      typeCars.value;
    };

    const formatNumber = (number) => {
      return numeral(number).format('0,0').replace(',', ' ');
    };
    function getCurrentTime() {
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const seconds = String(now.getSeconds()).padStart(2, "0");
      return `${hours}:${minutes}:${seconds}`;
    }
    const toggleCheckBox = () => {
      isChecked.value = !isChecked.value;
    };
    const toggleCheckBoxDown = () => {
      isCheckedDown.value = !isCheckedDown.value;
    };
    onMounted(() => {
      if (route.query.category) {
        changeBtn.value = route.query.category;
      }
    });
    onBeforeMount(() => {
      currentTime.value = getCurrentTime();
    });

    watch(
      () => route.query.category,
      (newVal) => {
        if (newVal === "Переїзд по Україні" || newVal === "Переїзд по місту") {
          changeBtn.value = newVal;
        }
      }
    );
    watch(inputValue, () => {
      if (inputValue.value === "") {
        boolInput.value = true;
      }
      if (inputValue.value !== "") {
        boolInput.value = false;
      }
    });

    watch(inputValueDown, () => {
      if (inputValueDown.value === "") {
        downInput.value = true;
      }
      if (inputValueDown.value !== "") {
        downInput.value = false;
      }
    });
    watch(currentDate, () => {
      setCurrentPrice();
    });
    watch(currentLoader, (val) => {
      if (val === "Не вказано") {
        currentLoader.value = 0;
      }
      setCurrentPrice();
    });
    watch(currentLoaders, (val) => {
      if (val === "Не вказано") {
        currentLoaders.value = 0;
      }
      setCurrentPrice();
    });
    watch(cityLoader, (val) => {
      if (val === "Не вказано") {
        cityLoader.value = 0;
      }
      setCurrentPrice();
    });
    watch(loadTime, (val) => {
      if (val === "Не вказано") {
        loadTime.value = 1;
      }
      setCurrentPrice();
    });
    watch(loaderTime, (val) => {
      if (val === "Не вказано") {
        loaderTime.value = 1;
      }
      setCurrentPrice();
    });
    watch(currentTime, () => {
      setCurrentPrice();
    });
    watch(selectCar, (val) => {
      if (val === "Не вказано") {
        selectCar.value = 0;
      }
      setCurrentPrice();
    });
    watch(selectCarCityinCity, (val) => {
      if (val === "Не вказано") {
        selectCarCityinCity.value = 0;
      }
      setCurrentPrice();
    });
    watch(currentWorkTime, (val) => {
      if (val === "Не вказано") {
        currentWorkTime.value = 1;
      }
      setCurrentPrice();
    });
    watch(changeBtn, () => {
      if(route.params.slug){

        router.push({
          path: `/${route.params.slug}`,
        
          query: {
            category: changeBtn.value,
          },
        });
      }else{
        router.push({
          query: {
            category: changeBtn.value,
          },
        });
      }
      // setCurrentPrice();
      typeCars.value = [];
      typeCarsCityInCity.value = [];
      currentPrice.value = 0;
      currentPriceMv.value = 0;
    });
    watch(currentCarTimes, (val) => {
      if (val === "Не вказано") {
        currentCarTimes.value = 0;
      }
      setCurrentPrice();
    });
    watch(selectValUp, (val) => {
      setCurrentPrice();
      if (selectValDown.value.city !== "" && val !== "") {
        viewCars.value = true;
      }
      if (selectValDown.value.city === "" || val === "") {
        viewCars.value = false;
      }
    });
    watch(selectValDown, (val) => {
      setCurrentPrice();
      if (selectValUp.value.city !== "" && val !== "") {
        viewCars.value = true;
      }
      if (selectValUp.value.city === "" || val === "") {
        viewCars.value = false;
      }
    });
    watch(place, (val) => {
      setCurrentPrice();
      if (place.value.city !== "" && val !== "") {
        viewCars.value = true;
      }
      if (place.value.city === "" || val === "") {
        viewCars.value = false;
      }
    });
    watch(isChecked, (val) => {
      if (val === false) {
        currentLoader.value = "";
        loadTime.value = "";
      }
      setCurrentPrice();
    });
    watch(isCheckedDown, (val) => {
      if (val === false) {
        currentLoaders.value = "";
        loaderTime.value = "";
      }
      setCurrentPrice();
    });

    // const slidesPerView = computed(() => {
    //   if (window.innerWidth < 420) {
    //     return 1;
    //   } else {
    //     return 4;
    //   }
    // });
    const cards = ref([
      {
        url: "img2.jpg",
        text: "Вантажні перевезення",
        text_info:
          "Швидкі вантажоперевезення по Дніпру та Україні від 50 кг до 5 тонн",
      },
      {
        url: "img.svg",
        text: "Квартирний переїзд",
        text_info: "Комплексні квартирні переїзди на професійному рівні",
      },
      {
        url: "img3.jpg",
        text: "Офісний переїзд",
        text_info: "Комплексні офісні переїзди по Дніпру за найкращими умовами",
      },
      {
        url: "img4.jpg",
        text: "Перевезення меблів",
        text_info: "Бережне перевезення та транспортування будь-яких меблів",
      },
      {
        url: "img.svg",
        text: "Переїзд під ключ",
        text_info: "Комплексні квартирні переїзди на професійному рівні",
      },
      {
        url: "img2.jpg",
        text: "Такелажні роботи",
        text_info: "Комплексні квартирні переїзди на професійному рівні",
      },
      {
        url: "img3.jpg",
        text: "Перевезення фортепіано",
        text_info: "Комплексні квартирні переїзди на професійному рівні",
      },
      {
        url: "img4.jpg",
        text: "Розбирання/Збирання меблів",
        text_info: "Комплексні квартирні переїзди на професійному рівні",
      },
      {
        url: "img.svg",
        text: "Пакування речей",
        text_info: "Комплексні квартирні переїзди на професійному рівні",
      },
      {
        url: "img2.jpg",
        text: "Пакувальний матеріал",
        text_info: "Комплексні квартирні переїзди на професійному рівні",
      },
      {
        url: "img3.jpg",
        text: "Доставка вантажів",
        text_info: "Комплексні квартирні переїзди на професійному рівні",
      },
      {
        url: "img.svg",
        text: "Піднімання будівельного матеріалу",
        text_info: "Комплексні квартирні переїзди на професійному рівні",
      },
      {
        url: "img2.jpg",
        text: "Вивіз хламу",
        text_info: "Комплексні квартирні переїзди на професійному рівні",
      },
      {
        url: "img4.jpg",
        text: "Вивіз будівельного сміття",
        text_info: "Комплексні квартирні переїзди на професійному рівні",
      },
    ]);

    return {
      checkSelectCar,
      checkSelectCars,
      viewCars,
      currentLoaders,
      clearForm,
      succesBlockVal,
      succesBlockView,
      selectOptionsTwo,
      selectOptions,
      inputValue,
      typeCars,
      clearFormLogistick,
      loader,
      currentDate,
      currentTime,
      cards,
      currentImage,
      t,
      timeWork,
      loaders,
      selectedOptionsUp,
      selectedOptionsDown,
      boolTime,
      selectPlace,
      modalPlace,
      selectValUp,
      selectValDown,
      sumPrice,
      currentPrice,
      currentPriceMv,
      carTime,
      selectCar,
      selectCarCityinCity,
      selectedCar,
      currentLoader,
      currentWorkTime,
      currentCarTimes,
      selectedLoader,
      selectedTimeWork,
      selectedCarTime,
      selectedCarCity,
      carsInfoPrice,
      cityRange,
      changeBtn,
      clickChangeBtn,
      boolInput,
      clearable,
      sendForm,
      inputValueDown,
      downInput,
      boolSelectValUp,
      boolSelectValDown,
      boolSelectCar,
      boolLoader,
      modalWindow,
      closeModalWindow,
      sendInfo,
      succesBlock,
      closeSuccesBlock,
      selectOptionsUp,
      selectOptionsDown,
      boolSelectPlace,
      closeModalWindowPlace,
      boolWorkerTime,
      boolCarTime,
      selectOptionsLogistick,
      selectLoaders,
      clearableTime,
      isChecked,
      isCheckedDown,
      clickChangeBtnMv,
      cityLoader,
      inCityLoader,
      selectCityLoader,
      toggleCheckBox,
      typeCarsCityInCity,
      toggleCheckBoxDown,
      workTimes,
      selectLoadTime,
      loadTime,
      workerTimes,
      selectLoaderTime,
      loaderTime,
      route,
      router,
      category,
      clearableUp,
      clearableDown,
      formatNumber
    };
  },
};
</script>
<style lang="scss">
.check-box {
  display: flex;
  gap: 10px;
  padding-left: 10px;
  padding-top: 10px;

  cursor: pointer;
  span {
    color: #23262f;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  }
}
.hr {
  width: 100%;
  background-color: #f2f4f5;
  height: 1px;
  border: none;
  background: #f2f4f5;
}
.worker {
  display: flex;
  align-items: baseline;
  padding-left: 5px;
  gap: 25px;
  .worker-loader {
    display: flex;
    align-items: baseline;
  }
  .worker-loaders-time {
    display: flex;
    align-items: baseline;
  }
  .loaders-time {
    display: flex;
    align-items: baseline;
  }
}
.after-worker {
  display: flex;
  align-items: baseline;
}
.loader-time {
  display: flex;
  align-items: baseline;
}
.bool-worker {
  display: flex;
  gap: 23px;
}
.reviews {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:30px;
  h2 {
    color: #23262f;
    font-family: "Manrope", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
    margin-bottom: 20px;
  }
}
.huflDf {
  display: none !important;
}
.countLoad {
  color: #23262f;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}
.valid-input {
  color: red;
  border-bottom: 2px dashed red;
}
.dp__pointer {
  border: none !important;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}
.dp__icon {
  display: none !important;
}
.dp__input_icon_pad {
  padding: 0;
}
.dp__input {
  padding: 4px 0px 0 7px;
}
.dp__input_wrap {
  width: 100px !important;
}

.bool-time-block {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .bool-time {
    display: flex;
    align-items: center;
    gap: 25px;
    .bool-calendar {
      position: relative;
      display: flex;
    }
    .bool-calendar::after {
      content: "";
      display: block;
      width: 1px;
      background-color: #979c9e;
      height: 100%;
      position: absolute;
      top: 0;
      right: -10%;
      transform: translateX(50%);
    }
    .bool-clock {
      position: relative;
      display: flex;
      align-items: center;
    }
  }
}
.cars {
  display: flex;
  .select-car {
    padding-left: 6px;
  }
  .select-time-car {
    display: flex;
    align-items: baseline;
  }
}

.wrapper-inputs {
  padding: 25px 25px 0px 25px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: flex-start;
}
.container {
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1440px;
  margin: 0 auto;
}
.container-small {
  padding: 0 15px;
  max-width: 1220px;
  margin: 0 auto;
}
.subsc-hr {
  width: 100%;
  background-color: #f2f4f5;
  height: 1px;
  border: none;
  background: #f2f4f5;
  position: relative;
  z-index: 1;
  position: absolute;
  left: 0;
}
.adaptiv-hr {
  height: 1px;
  border: none;
  background: #f2f4f5;
  z-index: 1;
  position: absolute;
  left: 0px;
  width: 100%;
}
.fontSize {
  font-size: 14px;
}

.swiper-slide-card {
  max-width: 280px;
}

.vue__time-picker input.vue__time-picker-input {
  border: none;
  width: 4em;
  height: none;
  padding: 0;
  font-size: 1em;
}

.vue__time-picker {
  width: 3em;
}

.vue__time-picker input.vue__time-picker-input {
  color: #23262f;
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  width: 3em;
}
.vue__time-picker .dropdown {
  left: -60px;
}

.choice {
  background: #23262f !important;
  border: 2px solid #23262f !important;
  span {
    color: #fff !important;
  }
}
.home {
  max-width: 1440px;
  overflow: hidden;
  margin: 0 auto;

  &__info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .info-img {
      background-image: url("../assets/Photo.jpg");
      background-size: cover;
      background-position: center;
      border-radius: 24px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 30px;
      .background-img {
        border-radius: 40px;
      }
      .partner {
        // position: absolute;
        top: 104px;
        max-width: 533px;
        left: 2%;
        .map {
          padding-top: 20px;
        }
        .partner-text {
          color: #fff;
          font-family: "Manrope", sans-serif;
          font-size: 55px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
        }
      }

      .partner-adaptiv {
        // position: absolute;
        top: 56px;
        left: 31px;
        max-width: 222px;
        span {
          color: #fff;
          font-family: "Manrope", sans-serif;
          font-size: 28px;
          font-style: normal;
          font-weight: 700;
          line-height: 40.5px;
        }
      }
      .application {
        display: flex;
        padding: 30px;
        gap: 20px;
        border-radius: 24px;
        border: 1px solid var(--neutrals-8, #fcfcfd);
        background: #fff;
        box-shadow: 0px 40px 64px -32px rgba(15, 15, 15, 0.1);
        flex-direction: column;
        hr {
          width: 100%;
          background-color: #f2f4f5;
          height: 1px;
          border: none;
          background: #f2f4f5;
        }
        .date {
          display: flex;
          flex-direction: column;
          padding-left: 6px;
          gap: 5px;
          .date-block {
            display: flex;
            align-items: center;
            gap: 10px;
          }
          .date-block-logisticks {
            display: flex;
            flex-direction: column;
            gap: 10px;
          }
          .geolocation-inputs-time {
            display: flex;
            align-items: center;
            // justify-content: space-around;
            .times {
              max-width: 75px;
            }
          }
          hr {
            width: 1px;
            height: 24px;
            background: #979c9e;
          }
          .time-hr {
            width: 1px;
            height: 24px;
            background: #979c9e;
          }
          .time {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            .calendar {
              position: relative;
              display: flex;
            }
            .calendar::after {
              content: "";
              display: block;
              width: 1px;
              background-color: #979c9e;
              height: 100%;
              position: absolute;
              top: 0;
              right: -4%;
              /* left: 0px; */
              transform: translateX(50%);
            }
            .clock {
              display: flex;
              position: relative;
            }
          }
        }
        .navigation-buttons {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          gap: 20px;
          .relocation {
            cursor: pointer;
            width: 203px;
            height: 40px;

            border-radius: 90px;
            gap: 12px;
            border-radius: 90px;
            background: white;
            border: 2px solid #e6e8ec;
            .relocation-text {
              color: #23262f;
              text-align: center;
              font-family: "Manrope", sans-serif;
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: 16px;
            }
          }

          .logistics {
            cursor: pointer;
            width: 203px;
            height: 40px;
            // padding: 12px 16px 12px 16px;
            border-radius: 90px;
            gap: 12px;
            border-radius: 90px;
            background: white;
            border: 2px solid #e6e8ec;
            .logistics-text {
              color: #23262f;
              text-align: center;
              font-family: "Manrope", sans-serif;
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: 16px;
            }
          }
        }

        .geolocation-inputs {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
        .geolocation-inputs-cars {
        }
        .cost-of-services {
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: center;
          gap: 12px;
          padding-bottom: 10px;
          .cost {
            color: #979c9e;
            font-family: "Manrope", sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */
          }
          .sum {
            color: #23262f;
            text-align: center;
            font-family: "Manrope", sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px; /* 133.333% */
          }
        }

        .reg-application {
          display: flex;
          justify-content: center;
          button {
            cursor: pointer;
            display: flex;
            width: 336px;
            padding: 16px 24px;
            justify-content: center;
            align-items: center;
            gap: 12px;
            border-radius: 90px;
            border: 1px solid #f58a1f;
            background: #f58a1f;
            span {
              color: #fff;
              text-align: center;
              font-family: "Manrope", sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px; /* 150% */
            }
          }
        }
      }
    }
  }
  &__card {
    .services {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 50px;
      h2 {
        color: #23262f;
        font-family: "Manrope", sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 38px;
        margin-left: 7px;
      }
      .services-btn {
        display: flex;
        padding-right: 0;
        gap: 10px;

        button {
          border: 1px solid transparent;
        }

        .right {
          cursor: pointer;
          display: flex;
          padding: 3px;
          align-items: flex-start;
          gap: 10px;
          border-radius: 40px;
          background: white;
        }
        .right:hover {
          border: 1px solid #999b9c;
        }
        .left {
          cursor: pointer;
          display: flex;
          padding: 3px;
          align-items: flex-start;
          gap: 10px;
          border-radius: 40px;
          background: white;
        }
        .left:hover {
          border: 1px solid #999b9c;
        }
      }
    }
    .cards {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .img {
      }
      .text {
        padding-top: 10px;
        span {
          color: #23262f;
          font-family: "Manrope", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
        }
      }
      .text-info {
        span {
          color: #72777a;
          font-family: "Manrope", sans-serif;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
  }
}
@media (max-width: 1300px) {
  .home__card {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 1220px) {
  .home__info .info-img .application .navigation-buttons {
    flex-direction: column;
    gap: 10px;
  }
  .home__info .info-img .application .date {
    flex-direction: column;
    gap: 6px;
  }
  .home__info .info-img .application .geolocation-inputs-cars {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    padding-left: 6px;
    gap: 10px;
  }
  .home__info .info-img .application {
    gap: 5px;
    padding: 27px;
  }
  .wrapper-inputs {
    padding: 25px 0px 0px 0px;
  }

  .home__info .info-img .application .date .geolocation-inputs-time {
    display: flex;
    align-items: center;
    margin-left: 13px;
    justify-content: space-around;
  }
  .home__info .info-img .application .date .time-hr {
    display: none;
  }
  .home__info .info-img .application .date hr {
    display: none;
  }
  .home__info .info-img .application .date {
    padding-left: 12px;
  }
}

@media (max-width: 1190px) {
  .home__card {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 1100px) {
  .home__info .info-img .partner .map {
    display: none;
  }
  .home__info .info-img {
    flex-direction: column;
    padding: 5px 0px 20px 0px;
    gap: 20px;
  }
}

@media (min-width: 770px) {
  .partner-adaptiv {
    display: none;
  }
  .adaptiv-hr {
    display: none;
  }
  .bool-worker .after-timeWorker::after {
    content: "";
    display: block;
    width: 1px;
    background-color: #979c9e;
    height: 68%;
    position: absolute;
    top: 6px;
    /* right: 6%; */
    left: -9px;
    transform: translateX(50%);
  }
}

@media (max-width: 770px) {
  .worker{
    gap:0;
    flex-direction: column;
  }
  .home__info {
    margin: 0 -15px;
  }
  .bool-worker {
    flex-direction: column;
    gap: 5px;
  }
  .subsc-hr {
    display: none;
  }
  .bool-time-block {
    padding-left: 6px;
  }
  .cars {
    flex-direction: column;
  }

  .home__info .info-img .application .cost-of-services {
    gap: 5px;
  }
  .home__info .info-img .application .geolocation-inputs-cars {
    padding-left: 3px;
  }
  .home__info .info-img .partner {
    padding-top: 30px;
  }
  .home__info .info-img .partner-adaptiv span {
    font-size: 36px;
    line-height: 51.5px;
  }

  .home__info .info-img .partner .partner-text {
    display: none;
  }
  .home__info .info-img .background-img {
    max-width: 755px;
    min-height: 576px;
  }

  .home__info .info-img .partner-adaptiv {
    max-width: 312px;
  }

  .sub-header__order-btn {
    display: none;
  }
  .sub-header__logo .lang {
    display: none;
  }
  .home__info .info-img .application .date .date-block {
    align-items: stretch;
    flex-direction: column;
    padding-left: 6px;
  }
  .home__info .info-img .application .date .geolocation-inputs-time {
    padding-right: 30px;
    margin-left: 0;
  }
  .home__info .info-img .application .navigation-buttons {
    flex-direction: row;
  }

  .home__info .info-img .application .date {
    flex-direction: column;
    padding-right: 0;
    padding-left: 0;
  }
  .home__info .info-img .application .date .geolocation-inputs-time {
    flex-direction: row;
  }

  .home__card .services .services-btn {
    margin-right: 0;
    padding-right: 0;
  }

  .sub-header__logo img {
    max-width: 124px;
  }
  .sub-header__logo {
    left: 2%;
  }
  .home__info .info-img .background-img {
    min-width: 386px;
  }
  .home__info .info-img .application .geolocation-inputs {
    padding: 0;
  }

  .home__info .info-img .application {
    top: 22%;
    right: 21px;
    min-width: 0;
    padding: 40px;
    gap: 20px;
    max-width: 266px;
    left: unset;
  }
}
@media (max-width: 550px) {
  .home__info .info-img .application .navigation-buttons {
    flex-direction: column;

    button {
      width: 100% !important;
    }
  }
}

@media (max-width: 480px) {
  .home__info .info-img {
    background-image: url("../assets/Rectangle 2729.jpg");
    background-size: cover;
    background-position: center;
  }
  .home__info .info-img .partner {
    //padding-top: 50px;
  }
  .wrapper-inputs{
    gap: 15px;
  }
  .home__info .info-img .application .date .date-block-logisticks{
    padding-left: 8px;
  }
  .home__info .info-img .partner-adaptiv span {
    font-size: 37px;
    line-height: 41.5px;
  }
}
@media (max-width: 400px) {
  .home__card .cards .text span {
    font-size: 20px;
  }
  .home__card .services .services-btn {
    margin-right: 0;
  }

  .sub-header__logo img {
    max-width: 124px;
  }

  .home__info .info-img .background-img {
    min-width: 386px;
  }
  .vuejs3-datepicker__value {
    margin-left: 3px;
    min-width: 86px !important;
  }

  .home__info .info-img .application .geolocation-inputs {
    padding: 0;
  }

  .home__info .info-img .application {
    padding: 40px 20px;
  }
  .home__info .info-img .partner-adaptiv{
    padding-left: 40px;
  }
}
@media(max-width:355px){
  .home__info .info-img .partner-adaptiv{
    padding-left: 30px;
    padding-right: 15px;
  }
}
</style>
