<template>
    <div class="page-single-wrapper" v-if="page && page.status === 'publish'">
        <div class="page-single">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                    <router-link to="/">Головна</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">{{ page.title }}</li>
                </ol>
            </nav>
            <h1>{{ page.title }}</h1>
            <div class="content-text-style" v-html="page.content"></div>
        </div>
    </div> 
    <div v-else-if="loading">
        <!-- Display a preloader while the data is being fetched -->
        <Preloader />
    </div>
    <div v-else>
        <!-- Display a message or a fallback content if the page is not published -->
        <Empty />
    </div>
</template>

<script>
import axios from 'axios'
import { pagesQuery } from "@/graphql/pages.query";
import Empty from '@/components/Empty.vue';
import Preloader from '@/components/Preloader.vue';

export default {
    name: "PageS",
    components: { Empty, Preloader},
    data() {
        return {
            page: {},
            loading: true
        };
    },
    mounted() {
        const slug = this.$route.params.slug;
        axios.post("https://admin.ser-ezhik.com.ua/graphql", {
            query: pagesQuery,
            variables: { slug }
        })
            .then(response => {
            this.page = response.data.data.pages.nodes.find(page => page.slug === slug && page.status === 'publish');
            this.loading = false;
        })
        .catch(error => {
            console.error(error);
            this.loading = false;
        });
    }
}
</script>