export const servicesQuery = `
query ServicesItems {
  allServices(first: 50) {
    nodes {
      id
      slug
      status
      title
      featuredImage {
        node {
          link
          altText
        }
      }
      excerpt
      content
    }
  }
}
`