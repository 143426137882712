<template>
    <div class="page-single-wrapper">
      <div class="page-single">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link to="/">Головна</router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
                Сторінки не існує
            </li>
          </ol>
        </nav>
        <h1>Сторінки не існує</h1>
        <div class="content-text-style flex center">
             <!-- <p class="center">Дані відсутні</p> -->
             <img class="center" :src="require('../assets/ser-404.svg')" />
        </div>        
      </div>
    </div>
  </template>

<script>
// import { ref, onMounted } from 'vue'

export default {
    name: "EmptyPost"
}
</script>