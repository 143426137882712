<template>
  <section class="succsec" v-if="showSucces === true">
    <div class="succes-container">
      <div class="succes-close">
        <button @click="$emit('closeSucces')">
          <img :src="require('../assets/close.svg')" />
        </button>
      </div>
      <div class="succes-block">
        <div class="succes-img">
          <img :src="require('../assets/Group 5.svg')" />
        </div>
        <div class="succes-text">
          <div class="title"><h2>Заявку надіслано</h2></div>
          <div class="text">
            <span>Ми зв'яжемося з вами через 15-20 хв!</span>
            <span>А зараз перегляньте наші послуги</span>
          </div>
        </div>
        <div class="succes-button">
          <button @click="$emit('services')">Послуги переглянути</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    showSucces: Boolean,
  },
};
</script>

<style lang="scss">
.succsec {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(56, 69, 93, 0.5);
  z-index: 1;
  left: 0;
}
.succes-container {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 775px;
  height: 575px;
  border-radius: 16px;
  background: #fcfcfd;
  transform: translate(-50%, -50%);
  box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.08);
  .succes-close {
    position: absolute;
    right: 0px;
    top: 0px;
    button {
      cursor: pointer;
      border-radius: 32px;
      background: var(--neutrals-8, #fcfcfd);
      box-shadow: 0px 8px 16px -8px rgba(15, 15, 15, 0.1);
      border: none;
      img {
        padding: 8px 10px 8px 10px;
      }
    }
  }
  .succes-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    padding-top: 72px;
  }
  .succes-text {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .title {
      h2 {
        color: #23262f;
        text-align: center;
        font-family: "Manrope", sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
      }
    }
    .text {
      display: flex;
      flex-direction: column;

      span {
        color: #72777a;
        text-align: center;
        font-family: "Manrope", sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }

  .succes-button {
    button {
      cursor: pointer;
      display: flex;
      border: none;
      justify-content: center;
      align-items: center;
      border-radius: 48px;
      background: #f58a1f;
      color: #fff;
      text-align: center;
      font-family: "Manrope", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      width: 239px;
      height: 48px;
    }
  }
}

@media (max-width: 820px) {
  .succes-container {
    width: 560px;
  }
}
@media (max-width: 600px) {
  .succes-container {
    width: 100%;
  }
}
</style>
