<template>
    <div class="page-single-wrapper">
        <div class="page-single home__card">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                    <router-link to="/">Головна</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">{{ $t("ourCars") }}</li>
                </ol>
            </nav>
            <h1>{{ $t("ourCars") }}</h1>
            <ul  v-if="cars.length > 0">
                <li
                    v-for="car in cars"
                    :key="car.id"
                    class="swiper-slide-card"
                >
                    <router-link :to="{ name: 'car', params: { slug: car.slug } }" class="cards">
                        <div class="img">
                            <img v-if="car.featuredImage" :src="car.featuredImage.node.link" :alt="car.featuredImage.node.altText">
                            <img v-else src="../assets/default-photo.jpg" alt="Default car Image">
                        </div>
                        <div class="text">
                            <span>{{ car.title }}</span>
                        </div>
                        <div class="text-info">
                            <span v-if="stripTags(car.excerpt).length > 0" v-html="stripTags(car.excerpt)"></span>
                            <span v-else>Заповніть будь ласка короткий заголовок, щоб тут відображався текст публікації</span>
                        </div>
                    </router-link>
                </li>
            </ul>
            <div v-else-if="loading">
                <!-- Display a preloader while the data is being fetched -->
                <Preloader />
            </div>
            <div v-else>
                <!-- Display a message or a fallback content if the data is not available -->
                <Empty />
            </div>
        </div>
    </div>  
</template>

<script>
import axios from 'axios'
import { carsQuery } from "@/graphql/cars.query";
import { useI18n } from "vue-i18n";
import Empty from '@/components/Empty.vue'
import Preloader from '@/components/Preloader.vue'

export default {
  name: "CarsView",
  components: {
    Empty,
    Preloader
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
    }
  },
  data() {
    return {
      cars: [],
      loading: true
    }
  },
  mounted() {
    axios.post('https://admin.ser-ezhik.com.ua/graphql', {
      query: carsQuery
    })
      .then(response => {
        this.cars = response.data.data.allCar.nodes
      })
      .catch(error => {
        console.error(error)
      })
  },
  methods: {
    stripTags(text) {
      return text.replace(/<p[^>]*>|<\/p>/g, '');
    }
  },
}
</script>
<style lang="scss">
  .home__card {

    ul {
        list-style: none;
        display: flex;
        flex-flow: row wrap;
        align-items: stretch;
        justify-content: flex-start;
        gap: 0;
        box-sizing: border-box;
        margin: 0 -10px;

        li {
            width: 25%;
            box-sizing: border-box;
            padding: 0 10px 20px;
            max-width: none;

            @media screen and (max-width: 1110px) {
              width: 33%;
            }

            @media screen and (max-width: 768px) {
              width: 50%;
            }

            a {

              .text {

                span {
                  transition: all ease-in-out .2s;
                }
              }

              &:hover {

                .img {

                  img {
                    transform: scale(1.1);
                  }
                }

                .text {

                  span {
                    color: #f58a1f;
                  }
                }
              }
            }
        }
    }

    .cards {
      text-decoration: none;

      .img {
        padding-bottom: 100%;
        position: relative;
        overflow: hidden;
        border-radius: 24px;

        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          border: 1px solid #f6f6f6;
          transition: all ease-in-out .2s;
        }
      }
    }
  }
</style>