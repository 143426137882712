<template>
  <section class="section-reviews">
    <div class="container">
      <div class="reviews">
        <!-- <img class="reviews__img" :src="require('../assets/Vector 2.svg')" /> -->
        <div class="reviews__title">
          <span>{{ $t("reviews-company") }}</span>
        </div>
        <div class="reviews__info">
          <swiper
            :loop-fill-group-with-blank="true"
            :navigation="true"
            :pagination="{ clickable: true }"
            :slides-per-view="'auto'"
            :space-between="10"
            :modules="modules"
            class="projects__slider-swiper"
          >
            <swiper-slide class="swiper-slide-card first">
              <div class="info center">
                  <div class="review-container">
                    <div class="review-header">
                      <h2 class="review-title">Відмінно</h2>
                      <div class="review-stars">
                        <span v-for="star in 5" :key="star" class="star">
                          <span v-html="stars"></span>
                        </span>
                      </div>
                      <p class="review-count">На основі {{ reviewCount }} рецензій</p>
                    </div>
                    <div class="review-logo">
                      <img src="https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png" alt="Google Logo">
                    </div>
                  </div>
                </div>
            </swiper-slide>
            <swiper-slide v-for="(review, index) in reviews" :key="index" class="swiper-slide-card">
              <div class="info">
                <div class="staff">
                  <div class="staff-img">
                    <img v-if="review.profile_photo_url" :src="review.profile_photo_url" alt="Profile Photo" style="width: 50px; height: 50px;"/>
                    <div v-else style="width: 50px; height: 50px;"></div>
                  </div>
                  <div class="staff-name">
                    <span class="name">
                      <img class="img-verified-google" :src="require('../assets/icon-google.svg')" />
                      {{ review.author_name }}
                    </span>
                    <span class="job-title">{{ review.relative_time_description }}</span>
                  </div>
                </div>
                <div class="comment">
                  <span class="work-info">
                    <span class="stars" v-html="getRatingStars(review.rating)"></span>
                    <img class="img-verified" :src="require('../assets/verified.svg')" />
                  </span>
                  <span class="text">
                    {{ review.text }}
                  </span>
                  <a :href="review.author_url" class="info-link" target="_blank">
                    Детальніше
                  </a>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import data from '../json/feedback.json';
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination } from "swiper/modules";
import { ref } from "vue";
import "swiper/css";
import "swiper/css/pagination";
export default {
  name: "ReviewsBlock",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      reviews: data.result.reviews
    };
  },
  setup() {
    const modules = ref([Pagination]);
    const rating = ref(data.result.rating);
    const reviewCount = ref(data.result.user_ratings_total);
    const reviewss = ref(data.result.reviews);
    const stars = '<svg width="30px" height="30px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:serif="http://www.serif.com/" viewBox="0 0 16 15" version="1.1" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><g transform="matrix(1,0,0,1,-427.432,-259.996)"><g transform="matrix(1.01647,0,0,1.01647,-14.9846,-123.719)"><path d="M442.181,378.04C442.286,377.716 442.588,377.497 442.928,377.497C443.268,377.497 443.569,377.716 443.674,378.04L444.991,382.098C445.03,382.217 445.106,382.321 445.207,382.395C445.309,382.469 445.432,382.509 445.557,382.509L449.824,382.507C450.164,382.507 450.465,382.726 450.57,383.05C450.675,383.373 450.56,383.727 450.285,383.927L446.833,386.434C446.731,386.508 446.655,386.612 446.616,386.731C446.577,386.851 446.578,386.98 446.616,387.099L447.936,391.156C448.041,391.48 447.926,391.834 447.651,392.034C447.376,392.234 447.003,392.234 446.728,392.034L443.278,389.525C443.176,389.451 443.054,389.411 442.928,389.411C442.802,389.411 442.68,389.451 442.578,389.525L439.127,392.034C438.852,392.234 438.48,392.234 438.205,392.034C437.929,391.834 437.814,391.48 437.92,391.156L439.239,387.099C439.278,386.98 439.278,386.851 439.239,386.731C439.201,386.612 439.125,386.508 439.023,386.434L435.571,383.927C435.296,383.727 435.18,383.373 435.285,383.05C435.391,382.726 435.692,382.507 436.032,382.507L440.298,382.509C440.424,382.509 440.547,382.469 440.648,382.395C440.75,382.321 440.826,382.217 440.864,382.098L442.181,378.04Z" style="fill:rgb(246,187,6);"/></g></g><script/></svg>';
    return {
      modules,
      rating,
      reviewCount,
      reviewss,
      stars
    };
  },
  methods: {
    getRatingStars(rating) {
      let stars = '';
      for (let i = 0; i < 5; i++) {
        if (i < rating) {
          stars += '<svg width="17px" height="17px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:serif="http://www.serif.com/" width="100%" height="100%" viewBox="0 0 16 15" version="1.1" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><g transform="matrix(1,0,0,1,-427.432,-259.996)"><g transform="matrix(1.01647,0,0,1.01647,-14.9846,-123.719)"><path d="M442.181,378.04C442.286,377.716 442.588,377.497 442.928,377.497C443.268,377.497 443.569,377.716 443.674,378.04L444.991,382.098C445.03,382.217 445.106,382.321 445.207,382.395C445.309,382.469 445.432,382.509 445.557,382.509L449.824,382.507C450.164,382.507 450.465,382.726 450.57,383.05C450.675,383.373 450.56,383.727 450.285,383.927L446.833,386.434C446.731,386.508 446.655,386.612 446.616,386.731C446.577,386.851 446.578,386.98 446.616,387.099L447.936,391.156C448.041,391.48 447.926,391.834 447.651,392.034C447.376,392.234 447.003,392.234 446.728,392.034L443.278,389.525C443.176,389.451 443.054,389.411 442.928,389.411C442.802,389.411 442.68,389.451 442.578,389.525L439.127,392.034C438.852,392.234 438.48,392.234 438.205,392.034C437.929,391.834 437.814,391.48 437.92,391.156L439.239,387.099C439.278,386.98 439.278,386.851 439.239,386.731C439.201,386.612 439.125,386.508 439.023,386.434L435.571,383.927C435.296,383.727 435.18,383.373 435.285,383.05C435.391,382.726 435.692,382.507 436.032,382.507L440.298,382.509C440.424,382.509 440.547,382.469 440.648,382.395C440.75,382.321 440.826,382.217 440.864,382.098L442.181,378.04Z" style="fill:rgb(246,187,6);"/></g></g><script xmlns=""/></svg>';
        } else {
          stars += '<svg width="17px" height="17px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:serif="http://www.serif.com/" width="100%" height="100%" viewBox="0 0 16 15" version="1.1" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><g transform="matrix(1,0,0,1,-468.008,-259.996)"><g transform="matrix(1.01647,0,0,1.01647,25.5914,-123.719)"><path d="M442.181,378.04C442.286,377.716 442.588,377.497 442.928,377.497C443.268,377.497 443.569,377.716 443.674,378.04L444.991,382.098C445.03,382.217 445.106,382.321 445.207,382.395C445.309,382.469 445.432,382.509 445.557,382.509L449.824,382.507C450.164,382.507 450.465,382.726 450.57,383.05C450.675,383.373 450.56,383.727 450.285,383.927L446.833,386.434C446.731,386.508 446.655,386.612 446.616,386.731C446.577,386.851 446.578,386.98 446.616,387.099L447.936,391.156C448.041,391.48 447.926,391.834 447.651,392.034C447.376,392.234 447.003,392.234 446.728,392.034L443.278,389.525C443.176,389.451 443.054,389.411 442.928,389.411C442.802,389.411 442.68,389.451 442.578,389.525L439.127,392.034C438.852,392.234 438.48,392.234 438.205,392.034C437.929,391.834 437.814,391.48 437.92,391.156L439.239,387.099C439.278,386.98 439.278,386.851 439.239,386.731C439.201,386.612 439.125,386.508 439.023,386.434L435.571,383.927C435.296,383.727 435.18,383.373 435.285,383.05C435.391,382.726 435.692,382.507 436.032,382.507L440.298,382.509C440.424,382.509 440.547,382.469 440.648,382.395C440.75,382.321 440.826,382.217 440.864,382.098L442.181,378.04Z" style="fill:rgb(204,204,204);"/></g></g><script xmlns=""/></svg>';
        }
      }
      return stars;
    }
  }
};
</script>

<style lang="scss" scoped>
.swiper-slide-card {
  max-width: 352px;

  &.first {
    display: flex;
    border-radius: 24px;
    border: 1px solid #e3e5e5;
    background: #fff;
    align-items: center;
    justify-content: center;
  }
}
.container-reviews {
  margin: 0 auto;
  background-image: url("../assets/Vector 2.svg");
  background-size: cover;
  background-position: inherit;
}
.reviews {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 80px;
  width: 100%;

  &__title {
    display: flex;
    justify-content: center;
    span {
      color: #23262f;
      font-family: "Manrope", sans-serif;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 38px;
    }
  }

  &__info {
    display: flex;
    gap: 24px;
    .info-link {
      color: #23262f;
      font-family: "Manrope", sans-serif;
      font-size: 14px;
    }
    .info {
      display: flex;
      padding: 40px;
      flex-direction: column;
      align-items: flex-start;
      gap: 32px;
      border-radius: 24px;
      border: 1px solid #e3e5e5;
      background: #fff;
      // height: 100%;


      &.center {
        align-items: center;
        justify-content: center;
        border: none;
      }

      .staff {
        display: flex;
        gap: 16px;
        .staff-img {
        }
        .staff-name {
          display: flex;
          flex-direction: column;
          gap: 8px;
          padding-top: 5px;
          .name {
            color: #000;
            font-family: "Manrope", sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            display: flex;
            align-items: center;
          }
          .job-title {
            color: var(--ink-lighter, #72777a);
            font-family: "Manrope", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
          }
        }
      }
      .comment {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .work-info {
          color: #000;
          font-family: "Manrope", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
        }
        .text {
          color: #72777a;
          font-family: "Manrope", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          height: 75px;
          overflow: hidden;
        }
      }

      .img-verified {
        width: 17px;
        height: 17px;
        margin-left: 10px;
      }
      .img-verified-google {
        width: 17px;
        height: 17px;
        margin-right: 10px;
      }

      .stars {

        & > svg {
          margin-right: 5px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .swiper {
      padding-bottom: 90px;

      .swiper-pagination {
        bottom: 20px;
      }
    }
  }
}
@media (max-width: 770px) {
  .reviews {
    background: none;
  }
  .reviews .reviews__title {
    span {
      font-size: 25px;
    }
  }
  .reviews .reviews__img {
    display: none;
  }
}
@media (max-width: 1210px) {
  .reviews .reviews__img {
    display: none;
  }
}
</style>
