<template>
  <div class="custom-select" ref="selectRef">
    <div class="icon">
      <img :src="iconSrc" alt="Icon" />
    </div>
    <div class="selected-option" @click="toggleDropdown">
      <span :class="fontSize">{{ selectedOption.city || placeholder }}</span>
    </div>
    <ul v-if="isOpen" class="options-list">
      <li
        v-for="(city, index) in citySelect"
        :key="index"
        @click="selectOption(city)"
      >
        {{ city.city }}
      </li>
    </ul>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";

export default {
  props: {
    iconSrc: String,
    citySelect: Array,
    placeholder: String,
    fontSize: String,
  },
  setup(props, { emit }) {
    const isOpen = ref(false);
    const selectedOption = ref("");
    const selectRef = ref(null);

    const toggleDropdown = () => {
      isOpen.value = !isOpen.value;
    };

    const selectOption = (city) => {
      selectedOption.value = city;
      isOpen.value = false;
      emit("selectOptions", selectedOption.value);
    };
    const handleClickOutside = (event) => {
      if (selectRef.value && !selectRef.value.contains(event.target)) {
        isOpen.value = false;
      }
    };
    onMounted(() => {
      document.addEventListener("click", handleClickOutside);
    });
    onBeforeUnmount(() => {
      document.removeEventListener("click", handleClickOutside);
    });

    return {
      isOpen,
      selectedOption,
      toggleDropdown,
      selectOption,
      selectRef,
    };
  },
};
</script>
<style lang="scss" scoped>
.fontSize {
  font-size: 14px !important;
}
.custom-select {
  position: relative;
  display: inline-block;
  min-width: 70px;
  margin-left: 4px;
}

.icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.selected-option {
  padding: 10px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  padding-left: 30px;
  span {
    color: #23262f;
    font-family: "Manrope", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  }
}

.arrow-icon {
  margin-left: 8px;
  transition: transform 0.3s;
}

.rotated {
  transform: rotate(180deg);
}

.options-list {
  z-index: 1;
  position: absolute;
  top: 100%;
  overflow: auto;
  max-height: 210px;
  left: 0;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  border-top: none;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  color: #23262f;
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}

.options-list li {
  padding: 10px;
  cursor: pointer;
}

.options-list li:hover {
  background-color: #f0f0f0;
}

//   @media (max-width: 600px) {
//     .selected-option span {
//       font-size: 15px;
//     }
//   }
</style>
