<template>
  <div class="custom-input">
    <label>{{ label }}</label>
    <input
      v-model="value"
      @input="$emit('update:modelValue', $event.target.value)"
      :placeholder="active ? '' : placeholder"
      @focus="active = true"
      @blur="active = false"
      :class="{ 'bool-input': active }"
    />
  </div>
</template>

<script>
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "CustomInput",
  props: {
    modelValue: String,
    label: String,
    placeholder: String,
  },
  setup(props, { emit }) {
    const value = ref(props.modelValue);
    const active = ref(false);

    const updateValue = (newValue) => {
      value.value = newValue;
      emit("update:modelValue", newValue);
    };
    //     const clearInputValue = () => {
    //   value.value = ''; // Обновляем значение value (которое связано с inputValue)
    //   emit("update:modelValue", ''); // Отправляем событие обновления входного значения
    // };
    watch(
      () => props.modelValue,
      (newValue) => {
        value.value = newValue;
      }
    );
    return {
      value,
      updateValue,
      active,
      // clearInputValue
    };
  },
});
</script>

<style lang="scss" scoped>
.custom-input {
  display: flex;
  input {
    cursor: pointer;
    width: 100%;
    border: none;
    color: var(--sky-dark, #979c9e);
    font-family: "Manrope", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    text-decoration-line: underline;
    outline: none;
    height: 30px;
    padding-left: 10px;
  }

  .bool-input {
    border-color: red;
  }
}
</style>
