<template>
    <section class="modal-window" v-if="showModal === true">
      <div class="container-modal-window">
        <div class="modal-close">
          <button @click="$emit('closeModal')">
            <img :src="require('../assets/close.svg')" />
          </button>
        </div>
        <div class="modal-blocks">
          <div class="modal-contacts">
            <div class="title">
              <h2>{{ $t('formTitleCal') }}</h2>
              <p>{{ $t('formSubTitle') }}</p>
            </div>
  
            <div class="inputs">
              <input
                type="text"
                :placeholder="$t('formName')"
                class="name-email"
                v-model="userName"
                :class="{ 'send-info': validUserName === true }"
              />
             
              <!-- <vue-tel-input  v-model="phone"  :default-country="'ua'" inputOptions.placeholder="ADASADD"></vue-tel-input> -->
              <VueTelInput
                v-model="phone"
                v-bind="bindProps"
                mode="international"
                :default-country="'ua'"
                :class="{ 'send-info': validUserPhone === true }"
                :validCharactersOnly="true"
                v-mask="'+38(0##) ###-##-##'"
              ></VueTelInput>
             
            </div>
            <div class="send-button" @click="checkModalValid">
              <button
                :disabled="!fieldsAreFilled"
                @click="sendUserInfo"
                :class="{ 'disabled-button': !fieldsAreFilled }"
              >
                {{ $t('formSubmit') }}
              </button>
            </div>
            <div class="info-message">
              {{ $t('formDesc') }} <span>{{ $t('formMin') }} </span>
            </div>
          </div>
          
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import { ref, computed, toRefs, watch } from "vue";
  import { VueTelInput } from "vue-tel-input";
  import "vue-tel-input/vue-tel-input.css";
  import { toast } from "vue3-toastify";
  import "vue3-toastify/dist/index.css";
  import {mask} from 'vue-the-mask'
  import axios from "axios";
  
  export default {
    name: "SendPriceInfoModal",
    components: {
      VueTelInput,
    },
    directives: {mask},
    props: {
      showModal: {
        type: Boolean,
        default: false,
      },
      sendInfo: {
        type: Object,
        default: () => {},
      },
      boolTime: {
        type: Boolean,
        default: false,
      },
      showSucces: {
        type: Boolean,
        default: false,
      },
    },
  
    setup(props, { emit }) {
      const bindProps = ref({
        mode: "international",
        inputOptions: {
          showDialCode: false,
          autofocus: false,
          id: "test",
          placeholder: "Номер телефона *",
        },
      });
  
      const phone = ref(null);
      const userName = ref("");
      const userEmail = ref("");
      const validUserName = ref(false);
      const validUserPhone = ref(false);
      const checkModalValid = () => {
        if (userName.value === "" || phone.value === "") {
          toast.error("Напишіть ваше ім`я та телефон", {
            autoClose: 1000,
          });
        }
      };
      const fieldsAreFilled = ref(false);
      const sendUserInfo = () => {
        if (userName.value === "") {
          validUserName.value = true;
          return;
        }
        if (userName.value !== "") {
          validUserName.value = false;
        }
  
        if (phone.value === "") {
          validUserPhone.value = true;
          return;
        }
        if (phone.value !== "") {
          validUserPhone.value = false;
        }
        const botToken = "6685662973:AAHI-9ndigaQqW7ur9578KR_VOolSYr5L0Q";
      const chatId = "-4094200753";     
       const message = `👋🏻 Замовлення оцінки

🙋‍♂️Ім’я : ${userName.value}
☎️Телефон : ${phone.value}
`;

      // Формируем URL для отправки сообщения
      const apiUrl = `https://api.telegram.org/bot${botToken}/sendMessage`;

      // Отправляем POST-запрос к API телеграмма
      axios
        .post(apiUrl, {
          chat_id: chatId,
          text: message,
        })
        .then((response) => {
          console.log("Сообщение успешно отправлено:", response.data);
          emit("succes");
        })
        .catch((error) => {
          console.error("Ошибка при отправке сообщения:", error);
          toast.error(error, {
            autoClose: 1000,
          });

        });
      };
      const { sendInfo } = toRefs(props);
      watch([userName, phone], () => {
        fieldsAreFilled.value =
          userName.value.trim() !== "" && phone.value.trim() !== "";
      });
      const carTimeLabel = computed(() => {
        const carTime = sendInfo.value.carTime;
  
        if (carTime === 1) {
          return "година";
        } else if (carTime >= 2 && carTime <= 4) {
          return "години";
        } else if (carTime >= 5 && carTime <= 10) {
          return "годин";
        } else {
          return "годин";
        }
      });
  
      const workTimeLabel = computed(() => {
        const workTime = sendInfo.value.workTime;
  
        if (workTime === 1) {
          return "година";
        } else if (workTime >= 2 && workTime <= 4) {
          return "години";
        } else if (workTime >= 5 && workTime <= 10) {
          return "годин";
        } else {
          return "годин";
        }
      });
  
      const loaderLabel = computed(() => {
        const loader = sendInfo.value.loader;
  
        if (loader === 1) {
          return "вантажник";
        } else if (loader >= 2 && loader <= 4) {
          return "вантажники";
        } else if (loader >= 5 && loader <= 10) {
          return "вантажників";
        } else {
          return "Не вказано";
        }
      });
      const loaderLabels = computed(() => {
        const loaders = sendInfo.value.loaders;
  
        if (loaders === 1) {
          return "вантажник";
        } else if (loaders >= 2 && loaders <= 4) {
          return "вантажники";
        } else if (loaders >= 5 && loaders <= 10) {
          return "вантажників";
        } else {
          return "Не вказано";
        }
      });
  
      const loaderTimeWork = computed(() => {
        const timeLoad = sendInfo.value.timeLoad;
        const times = sendInfo.value.times;
        if (timeLoad >= 1 && times >= 1) {
          return "Год.";
        } else {
          return "Не вказано";
        }
      });
  
      return {
        phone,
        bindProps,
        userName,
        userEmail,
        validUserName,
        validUserPhone,
        sendUserInfo,
        carTimeLabel,
        workTimeLabel,
        loaderLabel,
        loaderLabels,
        loaderTimeWork,
        checkModalValid,
        fieldsAreFilled,
      };
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .vti__dropdown-list{
    width: 353px !important;
  }

  .disabled-button {
    background-color: gray !important;
    cursor: not-allowed !important;
    color: white;
  }
  .send-info {
    border: solid 1px red !important;
  }
  .vue-tel-input {
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e3e5e5;
    background: white;
  }
  .vue-tel-input input {
    border-radius: 0 9px 9px 0 !important;
  }
  .modal-window {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(56, 69, 93, 0.5);
    z-index: 11;
    overflow-y: auto;
  }
  .modal-close {
    position: absolute;
    right: -15px;
    top: -20px;
    button {
      cursor: pointer;
      border-radius: 32px;
      background: var(--neutrals-8, #fcfcfd);
      box-shadow: 0px 8px 16px -8px rgba(15, 15, 15, 0.1);
      border: none;
      img {
        padding: 8px 10px 8px 10px;
      }
    }
  }
  .container-modal-window {
    position: absolute;
    left: 50%;
    top: 50%;
    min-width: 870px;
    min-height: 676px;
    border-radius: 16px;
    background: #fcfcfd;
    transform: translate(-50%, -50%);
    box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.08);
    display: flex;
    justify-content: center;
    align-items: center;
  
    .modal-blocks {
      display: flex;
      justify-content: center;
      gap: 10px;
      
     
  
      .modal-contacts {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
      
        .title {
          display: flex;
          flex-direction: column;
          gap: 16px;
          h2 {
            color: #23262f;
            text-align: center;
            font-family: "Manrope", sans-serif;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 36px;
          }
          p {
            color: #72777a;
            text-align: center;
            font-family: "Manrope", sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }
        .inputs {
          display: flex;
          flex-direction: column;
          gap: 16px;
         width:100%;
  
          .name-email {
            height: 48px;
            padding-left: 16px;
            border-radius: 5px;
            border: 1px solid #e3e5e5;
            background: #fff;
            color: var(--ink-lighter, #72777a);
            font-family: "Manrope", sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
          }
          .comment {
            border-radius: 8px;
            border: 1px solid #e3e5e5;
            background: #fff;
            padding-left: 16px;
            padding-top: 16px;
            color: var(--ink-lighter, #72777a);
            font-family: "Manrope", sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
          }
        }
        .send-button {
          button {
            cursor: pointer;
            display: flex;
            border: none;
            justify-content: center;
            align-items: center;
            border-radius: 48px;
            background: #f58a1f;
            color: #fff;
            text-align: center;
            font-family: "Manrope", sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            width: 327px;
            height: 48px;
          }
        }
        .info-message {
          color: var(--Dark, #23262f);
          text-align: center;
          font-family: "Manrope", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          span {
            color: #f58a1f;
          }
        }
      }
      
      .modal-price {
        padding-top: 20px;
        .price {
          display: flex;
          align-items: center;
          gap: 8px;
          justify-content: center;
  
          .info {
            color: var(--sky-dark, #979c9e);
            font-family: "Manrope", sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
          }
          .sum {
            color: var(--Dark, #23262f);
            text-align: center;
            font-family: "Manrope", sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 800;
            line-height: 32px;
          }
        }
      }
    }
  }
  @media (max-width: 1440px) {
    .container-modal-window .modal-blocks .modal-info .loaders .line {
      display: none;
    }
  }
  @media (max-width: 920px) {
    .container-modal-window {
      min-width: 685px;
    }
  }
  @media (max-width: 720px) {
    .container-modal-window {
      width: 100%;
      height: 100%;
      overflow: auto;
      border-radius: 0px;
      display: flex;
    justify-content: center;
    }
    .container-modal-window .modal-blocks {
      flex-direction: column-reverse;
      align-items: center;
      gap: 50px;
      // padding-top: 40px;
    }
    .modal-close {
      right: 0px;
      top: 0px;
    }
    .container-modal-window .modal-blocks .modal-info {
      padding-top: 0px;
      padding-left: 0px;
    }
    .container-modal-window .modal-blocks .modal-contacts {
      padding-bottom: 30px;
    }
  }
  @media(max-height:680px){
    .container-modal-window{
      top:70%
    }
  }
  @media(max-height:340px){
    .container-modal-window{
      top:110%
    }
  }
  </style>
  