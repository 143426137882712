<template>
  <section class="faq-section">
    <div class="container">
      <div class="main-block">
        <div class="main-block__info">
          <div class="title">
            <h2>{{ $t("frequentlyAskedQuestions") }}</h2>
          </div>
          <div class="nav-buttons">
            <button
              @click="searchBtn(1, 'Логістика')"
              :class="{ active: search === 1 }"
            >
              <span>{{ $t("logistics") }}</span>
            </button>
            <button
              @click="searchBtn(2, 'Переїзд')"
              :class="{ active: search === 2 }"
            >
              <span>{{ $t("relocation") }}</span>
            </button>
            <button
              @click="searchBtn(3, 'Перевезення')"
              :class="{ active: search === 3 }"
            >
              <span>{{ $t("transportation") }}</span>
            </button>
          </div>
        </div>

        <div
          class="main-block__informations"
          v-if="blcokCategory === 'Логістика'"
        >
          <div class="info-block" @click="checkText()">
            <div class="numb-text">
              <p class="numb" :class="{ 'numb-color': numbColor }">01</p>
              <p class="text">{{ $t("howToPrepareAparcel") }}</p>
            </div>
            <button :class="{ 'rotate-img': isRotated }">
              <img :src="require('../assets/Linesq.svg')" />
            </button>
          </div>
          <div class="text-block" v-if="textShow === true">
            <span>{{ $t("infoText00") }}</span>
            <span>
              {{ $t("infoText01") }}
            </span>
          </div>
          <hr />
          <div class="info-block" @click="checkText_02()">
            <div class="numb-text">
              <p class="numb" :class="{ 'numb-color': numbColor_02 }">02</p>
              <p class="text">{{ $t("textMenu2") }}</p>
            </div>
            <button :class="{ 'rotate-img': isRotated_02 }">
              <img :src="require('../assets/Linesq.svg')" />
            </button>
          </div>
          <div class="text-block" v-if="textShow_02 === true">
            <span>{{ $t("infoText00") }}</span>
            <span>
              {{ $t("infoText01") }}
            </span>
          </div>
          <hr />
          <div class="info-block" @click="checkText_03()">
            <div class="numb-text">
              <p class="numb" :class="{ 'numb-color': numbColor_03 }">03</p>
              <p class="text">{{ $t("textMenu3") }}</p>
            </div>
            <button :class="{ 'rotate-img': isRotated_03 }">
              <img :src="require('../assets/Linesq.svg')" />
            </button>
          </div>
          <div class="text-block" v-if="textShow_03 === true">
            <span>{{ $t("infoText00") }}</span>
            <span>
              {{ $t("infoText01") }}
            </span>
          </div>
          <hr />
          <div class="info-block" @click="checkText_04()">
            <div class="numb-text">
              <p class="numb" :class="{ 'numb-color': numbColor_04 }">04</p>
              <p class="text">{{ $t("textMenu4") }}</p>
            </div>
            <button :class="{ 'rotate-img': isRotated_04 }">
              <img :src="require('../assets/Linesq.svg')" />
            </button>
          </div>
          <div class="text-block" v-if="textShow_04 === true">
            <span>{{ $t("infoText00") }}</span>
            <span>
              {{ $t("infoText01") }}
            </span>
          </div>
          <hr />
        </div>

        <div class="main-block__informations" v-if="blcokCategory === 'Переїзд'">
          <div class="info-block" @click="checkText()">
            <div class="numb-text">
              <p class="numb" :class="{ 'numb-color': numbColor }">01</p>
              <p class="text">{{ $t("textMenu2") }}</p>
            </div>
            <button :class="{ 'rotate-img': isRotated }">
              <img :src="require('../assets/Linesq.svg')" />
            </button>
          </div>
          <div class="text-block" v-if="textShow === true">
            <span>{{ $t("infoText00") }}</span>
            <span>
              {{ $t("infoText01") }}
            </span>
          </div>
          <hr />
          <div class="info-block" @click="checkText_02()">
            <div class="numb-text">
              <p class="numb" :class="{ 'numb-color': numbColor_02 }">02</p>

              <p class="text">{{ $t("howToPrepareAparcel") }}</p>
            </div>
            <button :class="{ 'rotate-img': isRotated_02 }">
              <img :src="require('../assets/Linesq.svg')" />
            </button>
          </div>
          <div class="text-block" v-if="textShow_02 === true">
            <span>{{ $t("infoText00") }}</span>
            <span>
              {{ $t("infoText01") }}
            </span>
          </div>
          <hr />
          <div class="info-block" @click="checkText_03()">
            <div class="numb-text">
              <p class="numb" :class="{ 'numb-color': numbColor_03 }">03</p>
              <p class="text">{{ $t("textMenu4") }}</p>
            </div>
            <button :class="{ 'rotate-img': isRotated_03 }">
              <img :src="require('../assets/Linesq.svg')" />
            </button>
          </div>
          <div class="text-block" v-if="textShow_03 === true">
            <span>{{ $t("infoText00") }}</span>
            <span>
              {{ $t("infoText01") }}
            </span>
          </div>
          <hr />
          <div class="info-block" @click="checkText_04()">
            <div class="numb-text">
              <p class="numb" :class="{ 'numb-color': numbColor_04 }">04</p>
              <p class="text">{{ $t("textMenu3") }}</p>
            </div>
            <button :class="{ 'rotate-img': isRotated_04 }">
              <img :src="require('../assets/Linesq.svg')" />
            </button>
          </div>
          <div class="text-block" v-if="textShow_04 === true">
            <span>{{ $t("infoText00") }}</span>
            <span>
              {{ $t("infoText01") }}
            </span>
          </div>
          <hr />
        </div>

        <div
          class="main-block__informations"
          v-if="blcokCategory === 'Перевезення'"
        >
          <div class="info-block" @click="checkText()">
            <div class="numb-text">
              <p class="numb" :class="{ 'numb-color': numbColor }">01</p>
              <p class="text">{{ $t("howToPrepareAparcel") }}</p>
            </div>
            <button :class="{ 'rotate-img': isRotated }">
              <img :src="require('../assets/Linesq.svg')" />
            </button>
          </div>
          <div class="text-block" v-if="textShow === true">
            <span>{{ $t("infoText00") }}</span>
            <span>
              {{ $t("infoText01") }}
            </span>
          </div>
          <hr />
          <div class="info-block" @click="checkText_02()">
            <div class="numb-text">
              <p class="numb" :class="{ 'numb-color': numbColor_02 }">02</p>
              <p class="text">{{ $t("textMenu2") }}</p>
            </div>
            <button :class="{ 'rotate-img': isRotated_02 }">
              <img :src="require('../assets/Linesq.svg')" />
            </button>
          </div>
          <div class="text-block" v-if="textShow_02 === true">
            <span>{{ $t("infoText00") }}</span>
            <span>
              {{ $t("infoText01") }}
            </span>
          </div>
          <hr />
          <div class="info-block" @click="checkText_03()">
            <div class="numb-text">
              <p class="numb" :class="{ 'numb-color': numbColor_03 }">03</p>
              <p class="text">{{ $t("textMenu3") }}</p>
            </div>
            <button :class="{ 'rotate-img': isRotated_03 }">
              <img :src="require('../assets/Linesq.svg')" />
            </button>
          </div>
          <div class="text-block" v-if="textShow_03 === true">
            <span>{{ $t("infoText00") }}</span>
            <span>
              {{ $t("infoText01") }}
            </span>
          </div>
          <hr />
          <div class="info-block" @click="checkText_04()">
            <div class="numb-text">
              <p class="numb" :class="{ 'numb-color': numbColor_04 }">04</p>
              <p class="text">{{ $t("textMenu4") }}</p>
            </div>
            <button :class="{ 'rotate-img': isRotated_04 }">
              <img :src="require('../assets/Linesq.svg')" />
            </button>
          </div>
          <div class="text-block" v-if="textShow_04 === true">
            <span>{{ $t("infoText00") }}</span>
            <span>
              {{ $t("infoText01") }}
            </span>
          </div>
          <hr />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";
export default {
  name: "FrequentlyAskedQuestions",

  setup() {
    const search = ref(1);
    const textShow = ref(false);
    const textShow_02 = ref(false);
    const textShow_03 = ref(false);
    const textShow_04 = ref(false);
    const isRotated = ref(false);
    const isRotated_02 = ref(false);
    const isRotated_03 = ref(false);
    const isRotated_04 = ref(false);
    const numbColor = ref(false);
    const numbColor_02 = ref(false);
    const numbColor_03 = ref(false);
    const numbColor_04 = ref(false);
    const blcokCategory = ref("Логістика");
    const { t } = useI18n();
    const searchBtn = (mood, category) => {
      search.value = mood;
      blcokCategory.value = category;
    };

    const checkText = () => {
      textShow.value = !textShow.value;
      isRotated.value = !isRotated.value;
      numbColor.value = !numbColor.value;
    };
    const checkText_02 = () => {
      textShow_02.value = !textShow_02.value;
      isRotated_02.value = !isRotated_02.value;
      numbColor_02.value = !numbColor_02.value;
    };
    const checkText_03 = () => {
      textShow_03.value = !textShow_03.value;
      isRotated_03.value = !isRotated_03.value;
      numbColor_03.value = !numbColor_03.value;
    };
    const checkText_04 = () => {
      textShow_04.value = !textShow_04.value;
      isRotated_04.value = !isRotated_04.value;
      numbColor_04.value = !numbColor_04.value;
    };

    const handleClickOutside = (event) => {
      if (
        !event.target.closest(".info-block") &&
        !event.target.closest(".text-block")
      ) {
        textShow.value = false;
        isRotated.value = false;
        textShow_02.value = false;
        isRotated_02.value = false;
        textShow_03.value = false;
        isRotated_03.value = false;
        textShow_04.value = false;
        isRotated_04.value = false;
        numbColor.value = false;
        numbColor_02.value = false;
        numbColor_03.value = false;
        numbColor_04.value = false;
      }
    };

    onMounted(() => {
      document.addEventListener("click", handleClickOutside);
    });

    onBeforeUnmount(() => {
      document.removeEventListener("click", handleClickOutside);
    });

    return {
      search,
      searchBtn,
      textShow,
      checkText,
      textShow_02,
      textShow_03,
      textShow_04,
      checkText_02,
      checkText_03,
      checkText_04,
      t,
      blcokCategory,
      isRotated,
      isRotated_02,
      isRotated_03,
      isRotated_04,
      numbColor,
      numbColor_02,
      numbColor_03,
      numbColor_04,
    };
  },
};
</script>

<style lang="scss">
.rotate-img {
  transform: rotate(180deg);
}
.numb-color {
  color: #f58a1f !important;
}
.main-container {
  // max-width: 1145px;
  margin: 0 auto;
}
.active {
  background-color: #353945 !important ;
  span {
    color: white !important;
  }
}
.main-block {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  padding-left: 140px;
  padding-right: 140px;
  padding-top: 40px;
  padding-bottom: 140px;

  hr {
    width: 100%;
    background-color: #f2f4f5;
    height: 1px;
    border: none;
    background: #f2f4f5;
  }
  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;

    .title {
      h2 {
        color: #23262f;
        font-family: "Manrope", sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 38px;
      }
    }
    .nav-buttons {
      display: flex;
      gap: 12px;
      button {
        cursor: pointer;
        background-color: white;
        display: flex;
        padding: 8px 30px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 100px;
        border: none;
      }
      span {
        color: #72777a;
        font-family: "Manrope", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
      }
    }
  }
  &__informations {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    width: 80%;

    .text-block {
      display: flex;
      flex-direction: column;
      gap: 25px;
      max-width: 836px;
      span {
        color: #72777a;
        font-family: "Manrope", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .info-block {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 24px;
      width: 100%;
      justify-content: space-between;
      button {
        cursor: pointer;
        background: white;
        border: none;
      }
      .numb-text {
        display: flex;
        flex-direction: row;
        gap: inherit;
        .numb {
          color: #72777a;
          font-family: "Manrope", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
        .text {
          // width: 818px;
          color: #23262f;
          font-family: "Manrope", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }
  }
}
@media (max-width: 1190px) {
  .main-block {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 450px) {
  .main-block__informations .info-block .numb-text .text {
    font-size: 13px;
  }
}
@media (max-width: 600px) {
  .main-block__info .nav-buttons button {
    padding: 8px 20px;
  }
  .main-block {
    hr {
      width: 100%;
    }
  }
  .main-block__informations .info-block .text {
    font-size: 13px;
  }
}
</style>
