export const aboutQuery = `
    query OptionsPageQuery {
        optionsUK {
        settingsForPage {
            proNas {
            text
            title
            image {
                node {
                altText
                sourceUrl
                }
            }
            }
        }
        }
    }
`;
